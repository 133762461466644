import React from 'react';
import { StyledMenuBarSelect } from '../styled/MenuBarSelect.styled';

export interface MenuBarSelectProps {
    children: any;
    invert?: boolean;
    disabled?: boolean;
    title?: string;
    onClick?: any;
}

const MenuBarSelect = (props: MenuBarSelectProps) => {
    return (
        <StyledMenuBarSelect
            onClick={props.onClick}
            disabled={props.disabled}
            title={props.title}
            invert={props.invert}
        >
            {props.children}
        </StyledMenuBarSelect>
    );
};

export default MenuBarSelect;
