import { gql } from 'graphql.macro';

export const GetCreditLimitReviewData = gql`
    query GetCreditLimitReviewData($filter: CreditLimitReviewFilterModelInput) {
      creditLimitReviewData(filter: $filter) {
        accountReviewDate
        applicantName
        creditControllerName
        creditLimit
        customerId
        customerCompanyId
        mCSReference
        statusDescription
        }
    }
`;