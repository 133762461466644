import React, { FunctionComponent, useState } from 'react';
import { IconButton } from 'components/general/Button';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import { useSecurity } from 'utils/security/hooks/useSecurity';

interface ICreditLimitReviewDataDownloadProps {
    creditLimit: number;
    creditLimitRange: number;
    statusIds: number[];
    dateFrom: ISODateString;
    dateTo: ISODateString;
}

const CreditLimitReviewDataDownload: FunctionComponent<ICreditLimitReviewDataDownloadProps> = ({
    creditLimit,
    creditLimitRange,
    statusIds,
    dateFrom,
    dateTo
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { settings } = useConfig<IAppConfig>();
    const { accessToken } = useSecurity();

    const downloadFile = async () => {
        setIsDownloading(true);
        const method = 'api/File/GetCreditReviewReportData';
        const fileUrl = new URL(method, settings.CustomerServiceUrl);

        fetch(fileUrl.toString(), {
            method: 'POST',
            body: JSON.stringify({
                CreditLimit: creditLimit,
                CreditLimitRange: creditLimitRange,
                Statuses: statusIds,
                ReviewStartDate: dateFrom,
                ReviewEndDate: dateTo
            }),
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'Content-Security-Policy': "script-src 'self'"
            }
        })
            .then(res => res.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Credit Limit Review Report ${new Date().toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                })}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    return (
        <IconButton
            iconName={'Download'}
            onClick={downloadFile}
            disabled={isDownloading}
            title='Download credit limit review report'
        />
    );
};

export default CreditLimitReviewDataDownload;
