import React from 'react';
import { Callout, ICalloutProps } from '@fluentui/react';

interface ICustomerSuggestionsCalloutProps extends ICalloutProps {
    children: React.ReactNode;
}

const CustomerSuggestionsCallout = (props: ICustomerSuggestionsCalloutProps) => {
    return (
        <Callout isBeakVisible={false} {...props}>
            {props.children}
        </Callout>
    );
};

export default CustomerSuggestionsCallout;
