import styled from 'styled-components';

const StyledLoadingContainer = styled.div`
  ${({theme}) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 25px 0;

    h1 {
      padding: 15px 0 25px 0;
    }

    ul {
      list-style: disc;

      li {
        padding: 15px 0;
      }
    }

    svg {
      height: 30px;
      fill: ${theme.palette.primary.main};
    }
  `}
`;

const StyledLoadingLabel = styled.span`
  ${({theme}) => `
    font-size: ${theme.typography.subtitle.fontSize};
    color: ${theme.palette.common.black};
    margin-top: 5px;
  `}
`;

export { StyledLoadingContainer, StyledLoadingLabel };