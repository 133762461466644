import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/aplant.theme';
import { GlobalStyle } from 'components/shared/GlobalStyle/styled/GlobalStyle.styled';
import { ConfigProvider } from 'utils/config';
import SecurityProvider from 'utils/security/components/SecurityProvider';
import { AppSecurityRouter } from 'components/shared/App/components/AppSecurityRouter';

const App = () => {
    return (
        <ConfigProvider path={process.env.REACT_APP_PATH_TO_CONFIG}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Router>
                    <SecurityProvider>
                        <AppSecurityRouter />
                    </SecurityProvider>
                </Router>
            </ThemeProvider>
        </ConfigProvider>
    );
};

export default App;
