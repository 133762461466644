import styled from 'styled-components';

const StyledLoaderContainerStyled = styled.div`
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default StyledLoaderContainerStyled;
