import styled from 'styled-components';
import { FontIcon } from '@fluentui/react';

export const StyledTableContainer = styled.div`
    width: 100%;
`;

export const StyledTableCell = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    white-space: normal;
    word-break: break-word;
    height: 100%;
`;

export const StyledAddress = styled.div`
    flex: 1 0 100%;
    font-size: 11px;
    color: gray;
    line-height: 15px;
`;

export const StyledFontIcon = styled(FontIcon)`
    font-size: 16px;
    cursor: help;
`;
