import { useRef, useEffect, useState, useCallback } from 'react';

type DebounceTuple<TValue, TFunc extends Procedure> = [TValue, (value: TValue, ...args: Parameters<TFunc>) => void];

type GenericFunction = (...args: never[]) => unknown;
export type Procedure<TFunc extends GenericFunction = GenericFunction> = (
    ...args: Parameters<TFunc>
) => void | Promise<void>;

export interface IDebouncedFunction<TFunc extends Procedure> {
    (...args: Parameters<TFunc>): void | Promise<void>;
}

/**
 * Works the same as useState however after a time delay, executes the supplied function
 * Example:
 *    An example use case for this would be when you want to update a value instantly, i.e. typing in a text box
 *    but running an effect once the user stops typing for a set time, 400ms for example. This may be calling an API or
 *    running validation etc.
 * @param func The function called after the supplied time delay.
 * @param time The time delay.
 * @param stateValue When this changes, the value returned will automatically update. This allows an outside state value to be used.
 */
export function useDebounce<TValue, TFunc extends Procedure = Procedure>(
    func: Function,
    time: number,
    stateValue?: TValue | undefined
): DebounceTuple<TValue, TFunc> {
    const timer = useRef<number | null>(null);
    const [value, setValue] = useState<TValue>();
    const debounceValue = useRef<TValue>();

    useEffect(() => {
        if (debounceValue.current !== stateValue) {
            setValue(stateValue);
        }
    }, [stateValue]);

    const onChange = useCallback(
        (newValue: TValue, ...args: Parameters<TFunc>): void => {
            debounceValue.current = newValue;
            setValue(newValue);

            if (timer.current !== null) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(() => func(newValue, ...args), time);
        },
        [time, func]
    );

    return [value!, onChange];
}

/**
 * Calls a function after the specific time delay.
 * @param func The function called after the supplied time delay.
 * @param time The time delay.
 */
export function useFunctionDebounce<TFunc extends Procedure>(
    func: TFunc,
    time: number
): [IDebouncedFunction<TFunc>, boolean] {
    const timer = useRef<number | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);

    const callFunction = useCallback(
        (...args: Parameters<TFunc>): void => {
            if (timer.current !== null) {
                clearTimeout(timer.current);
            }

            setIsActive(true);

            timer.current = setTimeout(() => {
                func(...args);
                setIsActive(false);
            }, time);
        },
        [func, time]
    );

    return [callFunction, isActive];
}
