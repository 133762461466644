import { useState, useEffect } from "react";

/**
 * A hook that returns the current width and height of an element.
 * @param elementRef A reference to the element.
 */
export function useElementSize<TElement extends HTMLElement>(elementRef: TElement | null): { width: number, height: number } {
  const [ inputWidth, setInputWidth ] = useState<number>(0);
  const [ inputHeight, setInputHeight ] = useState<number>(0);

  useEffect(() => {
    function handleResize() {
      setInputWidth(elementRef?.offsetWidth || 0);
      setInputHeight(elementRef?.offsetHeight || 0);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [elementRef])

  return { width: inputWidth, height: inputHeight };
}