export const theme: Theme = {
    breakpoints: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    },
    palette: {
        common: {
            white: '#fff',
            black: '#000'
        },
        primary: {
            light: '#339d6a',
            main: '#008545',
            dark: '#005d30',
            contrastText: '#fff'
        },
        secondary: {
            light: '#fee880',
            main: '#fed203' /* #fed203 */,
            dark: '#b29301',
            contrastText: '#000'
        },
        error: {
            light: '#f6685e',
            main: '#f44336',
            dark: '#aa2e25',
            contrastText: '#fff'
        },
        warning: {
            light: '#ffcd38',
            main: '#ffc107',
            dark: '#b29301',
            contrastText: '#000'
        },
        success: {
            light: '#6fbf73',
            main: '#4caf50',
            dark: '#357a38',
            contrastText: '#000'
        },
        info: {
            light: '#4dabf5',
            main: '#2196f3',
            dark: '#1769aa',
            contrastText: '#fff'
        },
        text: {
            primary: '#000',
            secondary: '#008545',
            hint: '#616161',
            disabled: '#bdbdbd'
        },
        gray: {
            '50': '#fafafa',
            '100': '#f5f5f5',
            '200': '#eeeeee',
            '300': '#e0e0e0',
            '400': '#bdbdbd',
            '500': '#9e9e9e',
            '600': '#757575',
            '700': '#616161',
            '800': '#424242',
            '900': '#212121'
        },
        background: {
            default: '#fff',
            level1: '#eeeeee',
            error: 'rgba(250, 65, 0, 0.2)'
        },
        action: {
            disabledBackground: '#f5f5f5',
            hover: 'rgba(0,0,0,0.43)',
            hoverBackground: 'rgba(0,0,0,0.05)'
        },
        transparent: 'transparent',
        indicators: {
            highRisk: '#c00000',
            moderateRisk: '#c05046',
            neutralRisk: '#eeb300',
            lowRisk: '#92d050',
            minimalRisk: '#00934c'
        },
        errorMessage: {
            main: '#a4262c',
            secondary: '#c72830'
        }
    },
    shape: {
        borderRadius: '4px'
    },
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
        },
        duration: {
            shortest: '0.150s',
            shorter: '0.200s',
            short: '0.250s',
            standard: '0.300s',
            complex: '0.375s',
            enteringScreen: '0.225s',
            leavingScreen: '0.195s'
        }
    },
    zIndex: {
        drawer: 1200,
        modal: 1300,
        tooltip: 1400
    },
    typography: {
        fontFamily: '"Roboto", "Open Sans", sans-serif, "Arial"',
        fontSize: '13px',
        fontWeightRegular: 400,
        fontWeightBold: 600,
        fontWeightBolder: 700,
        h1: {
            fontFamily: '"Roboto Condensed", "Open Sans", "sans-serif", "Arial"',
            fontSize: '1.4em',
            fontWeight: 600,
            lineHeight: 1
        },
        h2: {
            fontFamily: '"Roboto Condensed","Open Sans", "sans-serif", "Arial"',
            fontSize: '1.2em',
            fontWeight: 600,
            lineHeight: 1
        },
        body: {
            fontFamily: '"Roboto","Open Sans", "sans-serif", "Arial"',
            fontSize: '1em',
            fontWeight: 400,
            lineHeight: 1
        },
        caption: {
            fontFamily: '"Roboto","Open Sans", "sans-serif", "Arial"',
            fontSize: '0.8em',
            fontWeight: 400,
            lineHeight: 1
        },
        label: {
            fontFamily: '"Roboto","Open Sans", "sans-serif", "Arial"',
            fontSize: '1.1em',
            fontWeight: 600,
            lineHeight: 1
        },
        subtitle: {
            fontFamily: '"Roboto","Open Sans", "sans-serif", "Arial"',
            fontSize: '0.9em',
            fontWeight: 400,
            lineHeight: 1
        },
        button: {
            fontFamily: '"Roboto Condensed","Open Sans", "sans-serif", "Arial"',
            fontSize: '1em',
            fontWeight: 600,
            lineHeight: 0.8
        }
    },
    shadow: '0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)'
};
