import { IRestClientOptions } from "./types/IRestClientOptions";
import { EndPoint } from "./EndPoint";

export class RestClient<TEndPoints = any> {
  private endpoints: SetDictionary<TEndPoints, EndPoint>;

  constructor(options: IRestClientOptions<TEndPoints>) {
    this.endpoints = options.endpoints;
  }

  public getEndpoint(name: keyof SetDictionary<TEndPoints, EndPoint>): EndPoint {
    return this.endpoints[name];
  }
}