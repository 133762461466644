import { SearchResult } from 'sections/search/pages/results/queries/search-results.queries.types';
import { isNullOrUndefined } from 'utils/validation.utils';
import {
    CustomerAccountRoute,
    customerAccountRoutes
} from 'sections/customer-accounts/shared/routes/customer-accounts.routes';
import { WatchlistStatusEnum } from 'sections/customer-accounts/shared/enums/WatchlistStatus.enum';
import { CustomerStatusEnum } from 'sections/customer-accounts/shared/enums/CustomerStatus.enum';
import { ApplicationStatusEnum } from 'sections/customer-accounts/shared/enums/ApplicationStatus.enum';
import { applicationTypes } from 'sections/customer-accounts/shared/constants/customer-status.constants';

/**
 * Returns whether a duplicate result is a watchlist item.
 * @param item The duplicate result.
 */
export const isWatchlistItem = (item: SearchResult): boolean => {
    return applicationTypes.Watchlist.includes(item.status.statusName.toUpperCase() as WatchlistStatusEnum);
};

/**
 * Returns whether a duplicate result is a customer application item.
 * @param item The duplicate result.
 */
export const isApplicationItem = (item: SearchResult): boolean => {
    return applicationTypes.Application.includes(item.status.statusName.toUpperCase() as ApplicationStatusEnum);
};

/**
 * Returns whether a duplicate result is a customer item.
 * @param item The duplicate result.
 */
export const isCustomerItem = (item: SearchResult): boolean => {
    return applicationTypes.Customer.includes(item.status.statusName.toUpperCase() as CustomerStatusEnum);
};

/**
 * Gets the url to view a company duplicate item depending on its type.
 * @param result The duplicate company item
 */
export const getViewUrl = (result: SearchResult | undefined | null): string | undefined => {
    if (isNullOrUndefined(result)) return undefined;

    if (isApplicationItem(result))
        return customerAccountRoutes[CustomerAccountRoute.ViewApplication](result.customerId.toString());

    if (isWatchlistItem(result))
        return customerAccountRoutes[CustomerAccountRoute.ViewWatchListItem](result.customerId.toString());

    if (isCustomerItem(result))
        return customerAccountRoutes[CustomerAccountRoute.ViewAccount](result.customerId.toString());

    return undefined;
};
