import { SearchResultsState } from 'sections/search/pages/results/store/state.types';
import {
    addSearchResults,
    resetSearch,
    SearchResultsActions,
    setFetchingResults,
    setPage,
    setSearchError,
    setSearchQuery
} from 'sections/search/pages/results/store/actions';
import { SearchResult } from 'sections/search/pages/results/queries/search-results.queries.types';
import { isNull, isUndefined } from 'utils/validation.utils';

export const setPageHandler: ActionHandler<SearchResultsState, typeof setPage> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    const searchResults = [...state.searchResults];

    const nextPageSize =
        state.totalResultsCount - (state.currentPage + 1) * state.resultsPerPage < state.resultsPerPage
            ? state.totalResultsCount - (state.currentPage + 1) * state.resultsPerPage
            : state.resultsPerPage;

    for (let i = 0; i < nextPageSize; i++) searchResults.push(null);

    return {
        ...state,
        currentPage: action.payload,
        searchResults: searchResults
    };
};

export const setSearchErrorHandler: ActionHandler<SearchResultsState, typeof setSearchError> = (state, action) => {
    return {
        ...state,
        searchError: action.payload
    };
};

export const setSearchQueryHandler: ActionHandler<SearchResultsState, typeof setSearchQuery> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    return {
        ...state,
        searchQuery: action.payload
    };
};

export const addSearchResultsHandler: ActionHandler<SearchResultsState, typeof addSearchResults> = (state, action) => {
    if (isUndefined(action.payload)) return state;

    const searchResults: (SearchResult | null)[] = [...state.searchResults.filter(x => !isNull(x))];
    searchResults.push(...action.payload.searchResults);

    return {
        ...state,
        searchResults: searchResults,
        totalResultsCount: action.payload.totalCount
    };
};

export const setFetchingResultsHandler: ActionHandler<SearchResultsState, typeof setFetchingResults> = (
    state,
    action
) => {
    if (isUndefined(action.payload)) return state;

    return {
        ...state,
        fetchingResults: action.payload
    };
};

export const resetSearchHandler: ActionHandler<SearchResultsState, typeof resetSearch> = state => {
    return {
        ...state,
        currentPage: 0,
        searchResults: []
    };
};

export const searchResultStateHandlers: ActionHandlerMapObject<SearchResultsState, typeof SearchResultsActions> = {
    [SearchResultsActions.SetPage]: setPageHandler,
    [SearchResultsActions.SetSearchError]: setSearchErrorHandler,
    [SearchResultsActions.SetSearchQuery]: setSearchQueryHandler,
    [SearchResultsActions.AddSearchResults]: addSearchResultsHandler,
    [SearchResultsActions.SetFetchingResults]: setFetchingResultsHandler,
    [SearchResultsActions.ResetSearch]: resetSearchHandler
};
