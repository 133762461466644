import { SearchResultsState } from 'sections/search/pages/results/store/state.types';

export const defaultSearchResultsState: SearchResultsState = {
    searchError: undefined,
    searchQuery: '',
    searchResults: [],
    currentPage: 0,
    resultsPerPage: 50,
    totalResultsCount: 0,
    fetchingResults: true
};
