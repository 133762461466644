import { ApolloError } from 'apollo-client';
import { isNullOrUndefined } from '../validation.utils';

export const errorMessageByStatusCode: { [key: string]: string } = {
    '401': '401: You are required to authenticate to access this resource.',
    '403': '403: You are not authorised to view this resource.',
    '404': '404: Resource not found.',
    '500': '500: Something went wrong.',
    'default': 'An unknown issue occurred.'
};

export const getErrorMessages = (error?: ApolloError): string[] => {
    const messages: string[] = [];

    if (isNullOrUndefined(error)) return messages;

    const networkError = error?.networkError as ApolloNetworkError;

    if (networkError && error?.graphQLErrors.length === 0) {
        !networkError.statusCode
            ? messages.push(errorMessageByStatusCode['default'])
            : messages.push(errorMessageByStatusCode[networkError.statusCode]);

        return messages;
    }

    if (!isNullOrUndefined(error)) {
        error.graphQLErrors?.forEach(error => messages.push(error.message));

        if (messages.length === 0) messages.push(error.message);
    }

    return messages;
};

export function isApolloError(item: unknown): item is ApolloError {
    if (typeof item !== 'object' || isNullOrUndefined(item)) return false;

    let isValid = true;
    if (!('message' in item)) isValid = false;
    if (!('graphQLErrors' in item)) isValid = false;
    if (!('networkError' in item)) isValid = false;
    if (!('extraInfo' in item)) isValid = false;

    return isValid;
}
