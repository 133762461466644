import styled from 'styled-components';

const StyledMenuBarDivider = styled.div`
  ${({theme}) => `
    width: 1px;
    background: ${theme.palette.gray[300]};
    margin: 0 5px;
  `}
  
`;

export { StyledMenuBarDivider };