export enum ApplicationStatusEnum {
    New = 'NEW',
    CreditCheck = 'CREDITCHECK',
    Pending = 'PENDING',
    CreditApproval = 'CREDITAPPROVAL',
    Declined = 'DECLINED',
    Cancelled = 'CANCELLED',
    Approved = 'APPROVED',
    IdVerification = 'IDVERIFICATION',
    IdVerificationAccepted = 'IDVERIFICATIONACCEPTED',
    IdVerificationRejected = 'IDVERIFICATIONREJECTED'
}
