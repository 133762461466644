import { IEndPointOptions } from './types/IEndPointOptions';
import { IPostOptions } from './types/IPostOptions';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { isNullOrEmpty } from 'utils/validation.utils';
import { IEndPointResponse } from './types/IEndPointResponse';

export class EndPoint {
    private instance: AxiosInstance;
    private headerlessInstance: AxiosInstance;

    constructor(options: IEndPointOptions) {
        this.instance = Axios.create({
            baseURL: options.url,
            headers: {
                ...options.defaultHeaders
            }
        });

        this.headerlessInstance = Axios.create({
            baseURL: options.url
        });

        if (!isNullOrEmpty(options.token)) {
            this.instance.interceptors.request.use(async request => {
                request.headers['Authorization'] = `Bearer ${options.token}`;
                return request;
            });
        }
    }

    public get<TResponse>(
        url: string,
        config?: AxiosRequestConfig,
        hasDefaultHeaders = true
    ): Promise<IEndPointResponse<TResponse>> {
        return hasDefaultHeaders
            ? this.instance.get<TResponse>(url, config)
            : this.headerlessInstance.get<TResponse>(url, config);
    }

    public post<TResponse>(options: IPostOptions): Promise<IEndPointResponse<TResponse>> {
        return this.instance.post<TResponse>(options.method, options.data, {
            onUploadProgress: options.onUploadProgress
        });
    }
}
