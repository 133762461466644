import React from 'react';
import { Modal as FabricModal, IconButton, mergeStyleSets, FontSizes, FontWeights } from '@fluentui/react';
import { theme } from 'themes/aplant.theme';
import { ConditionalRender } from '../../ConditionalRender';

interface IModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    isBlocking?: boolean;
    children: React.ReactNode;
    title: string;
    showDismiss?: boolean;
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
    },
    scrollContainer: {
        zIndex: 1
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    header: [
        {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            borderTop: `4px solid ${theme.palette.primary.main}`,
            display: 'flex',
            fontSize: FontSizes.xLarge,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
            height: '50px',
            backgroundColor: 'white',
            zIndex: 1
        }
    ],
    body: {
        flex: '4 4 auto',
        paddingTop: '50px',
        overflowY: 'hidden'
    }
});

const iconButtonStyles = mergeStyleSets({
    root: {
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    }
});

const Modal = ({ isOpen, onDismiss, isBlocking, children, title, showDismiss = true }: IModalProps) => {
    return (
        <FabricModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={isBlocking}
            containerClassName={contentStyles.container}
            scrollableContentClassName={contentStyles.scrollContainer}
        >
            <div className={contentStyles.header}>
                <span className={contentStyles.title}>{title}</span>
                <ConditionalRender condition={showDismiss}>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close popup modal'
                        onClick={onDismiss}
                    />
                </ConditionalRender>
            </div>
            <div className={contentStyles.body}>{children}</div>
        </FabricModal>
    );
};

export default Modal;
