export enum ApplicationPermission {
    ApplicationForm = 'applications:CreditApplicationForm',
    MakeDecision = 'applications:CustomerApplicationDecision',
    ExpiredApplications = 'applications:ExpiredApplications',
    IdVerification = 'applications:IdVerification',
    Documents = 'applications:documents',
    Watchlist = 'applications:Watchlist',
    Profiles = 'UserProfile',
    ImportUtility = 'applications:ImportUtility',
    CreditReviewReport = 'applications:CreditReviewReport'
}
