import React, { FC, useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GetLoggedInUserPermissionsResponse } from 'utils/permissions/queries/permissions.queries.types';
import { GetLoggedInUserPermissions } from 'utils/permissions/queries/permissions.queries';
import { ApplicationPermission } from 'utils/permissions/types/ApplicationPermission';
import { PermissionContext } from '../context/permissions.context';
import ApolloErrorToastMessage from 'utils/errors/components/ApolloErrorToastMessage';
import { useToasts } from 'react-toast-notifications';
import { isNullOrEmpty, isNullOrUndefined } from 'utils/validation.utils';
import { RequiredPermission } from 'utils/permissions/types/RequiredPermission';
import { User } from 'utils/security/types/User';
import { useSecurity } from '../../security/hooks/useSecurity';

/**
 * Props interface for {@link PermissionProvider}
 */
interface IPermissionProviderProps {
    user: Nullable<User>;
    children: React.ReactNode;
}

/**
 * PermissionProvider component
 * @param props {@link IPermissionProviderProps}
 */
const PermissionProvider: FC<IPermissionProviderProps> = ({ user, children }: IPermissionProviderProps) => {
    const [permissions, setPermissions] = useState<ApplicationPermission[]>([]);
    const [hasInitialised, setHasInitialised] = useState<boolean>(false);
    const { addToast } = useToasts();
    const apolloClient = useApolloClient();
    const security = useSecurity();
    const employeeId = user?.employeeId;

    useEffect(() => {
        if (!isNullOrEmpty(employeeId)) {
            apolloClient
                .query<GetLoggedInUserPermissionsResponse>({
                    query: GetLoggedInUserPermissions
                })
                .then(response => {
                    setPermissions(response.data.clientPermissions ?? []);
                    setHasInitialised(true);
                    security.updateUserID(response.data.logedinEmployeeUserId);
                })
                .catch(error => {
                    addToast(<ApolloErrorToastMessage error={error} baseMessage='Issue loading user permissions' />, {
                        appearance: 'warning'
                    });
                });
        }
    }, [employeeId, addToast, apolloClient]);

    const hasPermission = useCallback(
        (requiredPermission?: RequiredPermission): boolean => {
            if (isNullOrUndefined(requiredPermission)) return true;

            const permission = permissions.find(y => y.permissionKey === requiredPermission.permissionKey);

            if (isNullOrUndefined(permission)) return false;

            if (isNullOrUndefined(requiredPermission.permissionTypes)) return true;

            return requiredPermission.permissionTypes.all(x => permission[x]);
        },
        [permissions]
    );

    const hasPermissions = useCallback(
        (requiredPermissions?: RequiredPermission[]): boolean => {
            if (isNullOrUndefined(requiredPermissions)) return true;

            return requiredPermissions.any(hasPermission);
        },
        [hasPermission]
    );

    return (
        <PermissionContext.Provider
            value={{
                permissions,
                hasPermission,
                hasPermissions,
                initialised: hasInitialised
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
