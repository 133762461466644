export enum SearchMode {
    Any = 'ANY',
    All = 'ALL'
}

export enum SearchFilterDataType {
    Integer = 'INT',
    DateTime = 'DATETIME',
    String = 'STRING'
}

export enum SearchFilterExpression {
    Equal = 'EQUAL',
    NotEqual = 'NOTEQUAL',
    GreaterThan = 'GREATERTHAN',
    LessThan = 'LESSTHAN',
    GreaterThanOrEqual = 'GREATERTHANOREQUAL',
    LessThanOrEqual = 'LESSTHANOREQUAL',
    Contains = 'CONTAIN'
}

export enum OrderDirection {
    Asc = 'ASC',
    Desc = 'DESC'
}
