import { gql } from 'graphql.macro';

export const GetCustomerSearchResults = gql`
    query customerSearch($search: SearchModelInput) {
        customerSearch(search: $search) {
            customers {
                address {
                    line1
                    line2
                    line3
                    line4
                    postCode
                }
                applicantName
                applicationDate
                caseWorkerId
                caseWorkerName
                companyRegistrationNumber
                companyTradingName
                customerId
                dunsNumber
                importedFromLegacy
                mCSReference
                status {
                    statusDescription
                    statusId
                    statusName
                }
                uniqueId
                warningFlags {
                    warningFlagDescription
                    warningFlagId
                    warningFlagName
                }
            }
            recordCount
        }
    }
`;
