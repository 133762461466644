import { createContext } from 'react';
import { SecurityContextType } from 'utils/security/types/SecurityContextType';
import { AuthStateEnum } from 'utils/security/constants/AuthStateEnum';

const defaultSecurityContext: SecurityContextType = {
    accessToken: null,
    authState: AuthStateEnum.Pending,
    user: null,
    updateUserID : (userId: number) => {},
    signOut: () => Promise.resolve()
};

export const SecurityContext = createContext<SecurityContextType>(defaultSecurityContext);
