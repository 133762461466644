import styled from 'styled-components';

export const StyledField = styled.div`
    ${({ theme }) => `
        height: 32px;
        font-size: 14px;
        color: ${theme.palette.gray[900]};
        padding: 0 8px;
        border: 1px solid ${theme.palette.gray[500]};
        border-radius: 2px;
        background: ${theme.palette.gray[100]};
        display: flex;
        align-items: center;
        width: 100%;
         
        :hover {
            border-color: ${theme.palette.gray[900]};
        }
    `}
`;

export const StyledFieldText = styled.div`
    flex: 1;
    width: 100%;
`;

export const StyledFieldIcon = styled.div`
    ${({ theme }) => `
        flex: 0;
        user-select: none;
        font-size: 16px;
        color: ${theme.palette.gray[500]};
    `}
`;
