import styled from 'styled-components';
import { FontIcon } from '@fluentui/react';

export const StyledCustomerSuggestionItemContainer = styled.div`
    display: flex;
    ${({ theme }) => `
    padding: 20px 10px;
    cursor: pointer;
    
    :hover {
      background-color: ${theme.palette.gray[200]};
    }
  `}
`;

export const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
`;

export const StyledInformationContainer = styled.div``;

export const StyledCustomerSuggestionPrimary = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const StyledCustomerSuggestionSecondary = styled.div`
    font-size: 11px;
    color: gray;
`;

export const StyledCustomerNumber = styled.span`
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    color: ${({ theme }) => theme.palette.gray[600]};
    padding-left: 5px;
`;

export const StyledFontIcon = styled(FontIcon)`
    font-size: 16px;
    cursor: help;
`;
