import React, { useCallback, useState } from 'react';
import { LoadingState } from 'utils/loading/types/LoadingState';
import { LoadingContext } from '../context/loading.context';
import { LoadingTuple } from 'utils/loading/types/LoadingTuple';

/**
 * Props interface for {@link LoadingProvider}
 */
interface ILoadingProviderProps<T extends string | number> {
    /**
     * The initial loading state
     */
    loadingState: LoadingState<T>;

    /**
     * The children
     */
    children: React.ReactNode;

    loadingContext?: React.Context<LoadingTuple<T>>;
}

/**
 * LoadingProvider component
 * @param props {@link ILoadingProviderProps}
 */
const LoadingProvider = <T extends string | number>({
    loadingState,
    children,
    loadingContext
}: ILoadingProviderProps<T>) => {
    const Context = loadingContext ?? LoadingContext;
    const [state, setState] = useState<LoadingState<T>>(loadingState);

    const setLoading = useCallback((loadingStates: Partial<LoadingState<T>>): void => {
        setState(loadingState => ({ ...loadingState, ...loadingStates }));
    }, []);

    return <Context.Provider value={[state, setLoading]}>{children}</Context.Provider>;
};

export default LoadingProvider;
