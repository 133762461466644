/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: Fix react hook dependencies */

import React, { FC, useEffect, useState } from 'react';
import { DayReminderKey, IDayReminders } from '../types/IDayReminders';
import { Modal } from '../../../components/general/Modal';
import { useConfig } from '../../config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import Button from '../../../components/general/Button';
import { MonthFormat, renderDate } from '../../format.utils';
import { VirtualTable } from '../../../components/general/NewTable/Table';
import { IVirtualTableColumn } from 'components/general/NewTable/Table/components/VirtualTable';
import { useApolloClient } from '@apollo/react-hooks';
import { GetNotDestroyedItems } from '../queries/get-not-destroyed-items.queries';
import { UpdateDownloadDestroyed } from 'sections/customer-accounts/customers/components/DownloadApplication/mutate/download-original-application.mutate';
import Loading from '../../../components/shared/Loading';
import ApolloErrorToastMessage from '../../errors/components/ApolloErrorToastMessage';
import { useToasts } from 'react-toast-notifications';
import { usePermissions } from 'utils/permissions/hooks/usePermissions';
import { DailyReminderPermissionEnum } from 'utils/daily-reminders/enums/DailyReminderPermission.enum';
import { PermissionType } from 'utils/permissions/types/PermissionType';

interface DailyRemindersProps {
    children: React.ReactElement;
}

const DailyReminders: FC<DailyRemindersProps> = props => {
    const { settings } = useConfig<IAppConfig>();
    const [dailyReminders, setDailyReminders] = useState<IDayReminders[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const client = useApolloClient();
    const { addToast } = useToasts();
    const { hasPermission, initialised } = usePermissions();

    const requiresCheck = (lastRemindedDate: Date): boolean => {
        const frequencyMs = settings.ReminderFrequencyHours * 1000 * 60 * 60;
        const lastRemindedDateMs = lastRemindedDate.getTime();

        return Date.now() > lastRemindedDateMs + frequencyMs;
    };

    const setItemsToShow = (items: IDayReminders[]): void => {
        const itemsToShow = items.filter(item => {
            return requiresCheck(new Date(item.lastUpdatedDate));
        });

        if (itemsToShow && itemsToShow.length > 0) {
            setDailyReminders(itemsToShow);
        }
    };

    useEffect(() => {
        if (!initialised) return;

        (async function () {
            try {
                await nextCheck();
            } catch (err) {
                addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue loading not destroyed documents' />, {
                    appearance: 'warning'
                });
            }
        })();
    }, [addToast, initialised]);

    const getItems = async (): Promise<IDayReminders[]> => {
        if (
            !hasPermission({
                permissionKey: DailyReminderPermissionEnum.DownloadApplication,
                permissionTypes: [PermissionType.Read]
            })
        ) {
            return [];
        }

        const res = await client.query<{ downloadedApplicationsNotDestroyed: IDayReminders[] }>({
            query: GetNotDestroyedItems,
            fetchPolicy: 'network-only'
        });

        return res.data.downloadedApplicationsNotDestroyed;
    };

    const nextCheck = async () => {
        const timeout = settings.ReminderFrequencyHours * 1000 * 60 * 60;
        const lastCheckTime = localStorage.getItem(DayReminderKey);
        if (lastCheckTime != null) {
            if (requiresCheck(new Date(lastCheckTime))) {
                const items = await getItems();
                if (items.length > 0) {
                    setItemsToShow(items);
                }
                localStorage.setItem(DayReminderKey, new Date().toISOString());
            }
        } else {
            const items = await getItems();
            if (items.length > 0) {
                setItemsToShow(items);
            }
            localStorage.setItem(DayReminderKey, new Date().toISOString());
        }
        setTimeout(nextCheck, timeout);
    };

    const onDestroyNow = (referenceId: UID) => {
        setIsLoading(true);
        client
            .mutate({
                mutation: UpdateDownloadDestroyed,
                variables: {
                    request: {
                        destroyed: true,
                        referenceId: referenceId
                    }
                }
            })
            .then(() => {
                if (dailyReminders.length === 1) {
                    localStorage.setItem(DayReminderKey, new Date().toISOString());
                }
                setDailyReminders(dailyReminders.filter(x => x.referenceId !== referenceId));
            })
            .catch(err => {
                console.error(err);
                addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue loading user permissions' />, {
                    appearance: 'warning'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onDestroyLater = (referenceId: UID) => {
        setIsLoading(true);
        client
            .mutate({
                mutation: UpdateDownloadDestroyed,
                variables: {
                    request: {
                        destroyed: false,
                        referenceId: referenceId
                    }
                }
            })
            .then(() => {
                if (dailyReminders.length === 1) {
                    localStorage.setItem(DayReminderKey, new Date().toISOString());
                }
                setDailyReminders(dailyReminders.filter(x => x.referenceId !== referenceId));
            })
            .catch(err => {
                console.error(err);
                addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue loading user permissions' />, {
                    appearance: 'warning'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getColumns = (
        onDestroyNow: (referenceId: UID) => void,
        onDestroyLater: (referenceId: UID) => void
    ): IVirtualTableColumn<IDayReminders>[] => {
        return [
            {
                data: 'string',
                isPadded: true,
                isResizable: true,
                isRowHeader: true,
                key: 'fileName',
                minWidth: 350,
                name: 'File Name',
                onRender: (item: IDayReminders) => <span>{item.fileName}</span>,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A'
            },
            {
                data: 'date',
                isPadded: true,
                isResizable: true,
                isRowHeader: true,
                key: 'dateCreated',
                minWidth: 150,
                name: 'Date Created',
                onRender: (item: IDayReminders) => (
                    <span>
                        {renderDate(item.dateCreated, {
                            month: MonthFormat.Short
                        })}
                    </span>
                )
            },
            {
                data: 'date',
                isPadded: true,
                isResizable: true,
                isRowHeader: true,
                key: 'dateCreated',
                minWidth: 150,
                name: 'Date Updated',
                onRender: (item: IDayReminders) => (
                    <span>
                        {renderDate(item.lastUpdatedDate, {
                            month: MonthFormat.Short
                        })}
                    </span>
                )
            },
            {
                data: 'button',
                isPadded: true,
                isResizable: true,
                key: 'destroyNow',
                minWidth: 100,
                name: 'Destroy Now',
                onRender: (item: IDayReminders) => (
                    <Button text={'Destroy Now'} onClick={() => onDestroyNow(item.referenceId)} />
                )
            },
            {
                data: 'button',
                isPadded: true,
                isResizable: true,
                key: 'destroyLater',
                minWidth: 100,
                name: 'Destroy Later',
                onRender: (item: IDayReminders) => (
                    <Button text={'Destroy Later'} onClick={() => onDestroyLater(item.referenceId)} />
                )
            }
        ];
    };

    return (
        <>
            <Modal
                isOpen={dailyReminders.length > 0}
                onDismiss={() => {}}
                showDismiss={false}
                title={'Daily Reminders'}
            >
                <Loading message={''} overlay={true} isLoading={isLoading}>
                    <div style={{ margin: '20px', minHeight: '70vh' }}>
                        <VirtualTable<IDayReminders>
                            items={dailyReminders}
                            columns={getColumns(onDestroyNow, onDestroyLater)}
                        />
                    </div>
                </Loading>
            </Modal>
            {props.children}
        </>
    );
};

export default DailyReminders;
