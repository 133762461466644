import React, { CSSProperties } from 'react';
import {
    StyledSideMenuLink,
    StyledIcon,
    StyledLabel,
    StyledSideMenuLinkChild,
    StyledChildIcon
} from '../styled/SideMenu.styled';
import { useRouteMatch } from 'react-router-dom';
import { isNullOrUndefined } from 'utils/validation.utils';

interface SideMenuLinkProps {
    icon: React.ReactElement;
    label: string;
    to: string;
    title?: string;
    items?: {
        to: string;
        exact?: boolean;
        title: string;
        icon: React.ReactElement;
        label: string;
    }[];
    style?: CSSProperties;
}

const SideMenuLink = ({ to, title, icon, label, items, style }: SideMenuLinkProps) => {
    const match = useRouteMatch(to);

    return (
        <div style={style}>
            <StyledSideMenuLink to={to} title={title}>
                <StyledIcon>{icon}</StyledIcon>
                <StyledLabel>{label}</StyledLabel>
            </StyledSideMenuLink>

            {!isNullOrUndefined(match) &&
                !isNullOrUndefined(items) &&
                items.map((item, i) => (
                    <StyledSideMenuLinkChild key={i} to={item.to} exact={item.exact} title={item.title}>
                        <StyledChildIcon>{item.icon}</StyledChildIcon>
                        <StyledLabel>{item.label}</StyledLabel>
                    </StyledSideMenuLinkChild>
                ))}
        </div>
    );
};

export default SideMenuLink;
