export enum DocumentType {
    PersonalIdentification = 1,
    CompanyLetterhead = 2,
    CreditReport = 3,
    InsurancePolicyDocument = 4,
    DeedOfGuarantee = 5,
    ApplicationForRentalInsurance = 6,
    ConsolidatedApplicationPack = 7,
    Other = 8,
    IdVerificationDocument = 9
}
