import { isNullOrEmpty, isUndefined } from './validation.utils';

export enum DayFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum MonthFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit',
    Long = 'long',
    Short = 'short',
    Narrow = 'narrow'
}
export enum YearFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum WeekdayFormat {
    Long = 'long',
    Short = 'short',
    Narrow = 'narrow'
}

export enum HourFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum MinuteFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

export enum SecondFormat {
    Numeric = 'numeric',
    TwoDigit = '2-digit'
}

type RenderDateFormat = {
    day?: DayFormat;
    month?: MonthFormat;
    year?: YearFormat;
    weekday?: WeekdayFormat;
};

type RenderTimeFormat = {
    hour?: HourFormat;
    minute?: MinuteFormat;
    second?: SecondFormat;
    twelveHour?: boolean;
};

const defaultFiller = '--';
const defaultDateFormat = { day: 'numeric', month: 'numeric', year: 'numeric' };
const defaultTimeFormat = { hour: HourFormat.TwoDigit, minute: MinuteFormat.TwoDigit };

/**
 * Returns a value or a default filler value if it is null or undefined.
 * @param value The value to return.
 * @param defaultValue The default filler value.
 */
export function renderValue(value?: string | null, defaultValue = defaultFiller): string {
    if (isNullOrEmpty(value)) {
        return defaultValue;
    }

    return value;
}

/**
 * Takes a date string in ISO format and formats to a locale format.
 * @param value The ISO date string.
 * @param format Date format options
 */
//.toLocaleDateString('en-GB', dateOptions)
//{ day: 'numeric', month: 'short', year: 'numeric' }
export function renderDate(value?: string, format?: RenderDateFormat) {
    const date = isUndefined(value) ? new Date() : new Date(value);

    return date.toLocaleDateString([], { ...defaultDateFormat, ...format });
}

export function renderTime(value: string, format?: RenderTimeFormat) {
    const date = new Date(value);

    return date.toLocaleTimeString([], { ...defaultTimeFormat, ...format });
}

/**
 * Takes a date string in ISO format and formats to a locale format.
 * @param value The ISO date string.
 * @param format Date format options
 */
//.toLocaleDateString('en-GB', dateOptions)
//{ day: 'numeric', month: 'short', year: 'numeric' }
export function renderNullableDate(value?: string, format?: RenderDateFormat) {
    if(isNullOrEmpty(value))
    return value;
    const date = new Date(value);

    return date.toLocaleDateString([], { ...defaultDateFormat, ...format });
}

/**
 * Join an array of string values using a separator.
 * @param values The array of values to join.
 * @param separator The separator to use between values.
 * @param defaultValue The value returned if the supplied values are all null or undefined.
 */
export function joinValues(
    values: Array<string | undefined | null>,
    separator = ', ',
    defaultValue = defaultFiller
): string {
    const filteredValues = values.filter(value => !isNullOrEmpty(value));

    if (filteredValues.length === 0) {
        return defaultValue;
    }

    return filteredValues.join(separator);
}

export function formatFileSize(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatNumber = (number: number | null): string => {
    if (isNullOrEmpty(number)) {
        return '';
    }

    return number.toLocaleString();
};
