import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/Images/logo-black.svg';

export const StyledPostLogoutContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const StyledContainer = styled.div`
    text-align: center;
    width: 60vw;
    min-width: min-content;
    padding: 0 25px;
    box-shadow: ${({ theme }) => theme.shadow};
    border-radius: 10px;
`;

export const StyledLogoContainer = styled.div`
    padding: 25px 0;
`;

export const StyledLogo = styled(Logo)`
    height: 150px;
    max-height: 150px;
`;

export const StyledTextContainer = styled.div`
    padding: 25px 0;
    text-align: center;
`;

export const StyledText = styled.h1`
    display: block;
    font-size: 1.5em;
`;
