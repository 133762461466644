import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import App from 'components/shared/App';
import 'extensions/string.extensions';
import 'extensions/number.extensions';
import 'extensions/array.extensions';
import { setupFluentUiTheme } from 'themes/fluentui.theme';

setupFluentUiTheme();

ReactDOM.render(<App />, document.getElementById('root'));
