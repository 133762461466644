import { IAppConfig } from 'config/types/IAppConfig';

/** The available auth routes in the application */
export enum AuthRoute {
    Issuer = 'issuer',
    RedirectUrl = 'redirect-url',
    ImplicitCallback = 'implicit-callback',
    PostLogout = 'post-logout'
}

/** The auth route collection */
export const routes: { [key in AuthRoute]: (settings?: IAppConfig) => string } = {
    [AuthRoute.Issuer]: settings => `${settings?.OktaOrgUrl}/oauth2/${settings?.OktaAuthorisationServerId}`,
    [AuthRoute.RedirectUrl]: () => `${window.location.origin}/implicit/callback`,
    [AuthRoute.ImplicitCallback]: () => '/implicit/callback',
    [AuthRoute.PostLogout]: () => '/postlogout'
};
