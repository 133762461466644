import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface StyledMenuContainerProps {
    isExpanded?: boolean;
}

const StyledMenuContainer = styled.div<StyledMenuContainerProps>`
    ${({ theme, isExpanded }) => `
    min-width: ${isExpanded ? '222px' : '48px'};
    max-width: ${isExpanded ? '222px' : '48px'};
    background-color: ${theme.palette.gray[900]};
    transition: all ${theme.transitions.duration.shorter} ${theme.transitions.easing.sharp};
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  `}
`;

const StyledSideMenuLink = styled(NavLink)`
    ${({ theme }) => `
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${theme.palette.common.white};
    user-select: none;
    text-decoration: none;

    &.active {
      background: ${theme.palette.gray[800]};
    }

    :hover {
      color: ${theme.palette.secondary.main};
      background: ${theme.palette.gray[700]};
    }
  `}
`;

export const StyledSideMenuLinkChild = styled(NavLink)`
    ${({ theme }) => `
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${theme.palette.gray[400]};
    user-select: none;
    text-decoration: none;
    border-left: 3px solid ${theme.palette.gray[600]};
    background: ${theme.palette.gray[800]};

    &.active {
      color: ${theme.palette.common.white};
      background: ${theme.palette.gray[700]};
      border-left: 3px solid ${theme.palette.secondary.main};
    }

    :hover {
      color: ${theme.palette.secondary.main};
      background: ${theme.palette.gray[700]};
    }
  `}
`;

const StyledSideMenuAction = styled.div`
    ${({ theme }) => `
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${theme.palette.gray[400]};
    user-select: none;

    :hover {
      color: ${theme.palette.secondary.main};
      background: ${theme.palette.gray[700]};
    }

    &.active {
      color: ${theme.palette.common.white};
    }
  `}
`;

const StyledIcon = styled.div`
    ${({ theme }) => `
    height: 50px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: ${theme.palette.primary.light};
  `}
`;

export const StyledChildIcon = styled.div`
    ${({ theme }) => `
    height: 50px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: ${theme.palette.common.white};
  `}
`;

const StyledLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
`;

export { StyledMenuContainer, StyledSideMenuLink, StyledSideMenuAction, StyledIcon, StyledLabel };
