import React from 'react';
import { IVirtualTableColumn } from 'components/general/NewTable/Table/components/VirtualTable';
import { SearchResult } from 'sections/search/pages/results/queries/search-results.queries.types';
import {
    StyledAddress,
    StyledFontIcon,
    StyledTableCell
} from 'sections/search/pages/results/components/SearchResults.styles';
import { joinValues, renderDate } from 'utils/format.utils';
import { IconButton } from '@fluentui/react';
import {
    isApplicationItem,
    isCustomerItem,
    isWatchlistItem
} from 'sections/search/pages/results/utils/search-results.utils';
import { theme } from 'themes/aplant.theme';

export function getColumns(
    onViewRow: (searchResult?: SearchResult | null) => void
): IVirtualTableColumn<SearchResult | null>[] {
    return [
        {
            //Customer type (Application, Customer, Watchlist)
            data: 'string',
            isPadded: false,
            key: 'type',
            minWidth: 30,
            maxWidth: 30,
            name: '',
            onRender: (item: SearchResult) => (
                <StyledTableCell style={{ justifyContent: 'center' }}>
                    {isApplicationItem(item) ? (
                        <StyledFontIcon iconName='AddFriend' title='Customer Application' />
                    ) : isCustomerItem(item) ? (
                        <StyledFontIcon iconName='Contact' title='Customer' />
                    ) : isWatchlistItem(item) ? (
                        <StyledFontIcon
                            iconName='ShieldAlert'
                            style={{ color: theme.palette.error.main }}
                            title='Watchlist'
                        />
                    ) : (
                        <StyledFontIcon iconName='StatusCircleQuestionMark' title='Unknown' />
                    )}
                </StyledTableCell>
            )
        },
        {
            //Customer Id
            data: 'number',
            isPadded: true,
            key: 'customerId',
            minWidth: 100,
            maxWidth: 100,
            name: 'Customer Id',
            onRender: (item: SearchResult) => <StyledTableCell>{item.customerId.toString()}</StyledTableCell>
        },
        {
            //Company name and address
            data: 'object',
            isPadded: true,
            key: 'applicantName',
            minWidth: 100,
            name: 'Applicant Name',
            onRender: (item: SearchResult) => (
                <StyledTableCell>
                    <b>{item.applicantName}</b>
                    <StyledAddress>
                        {joinValues([
                            item.address.line1,
                            item.address.line2,
                            item.address.line3,
                            item.address.line4,
                            item.address.postCode
                        ])}
                    </StyledAddress>
                </StyledTableCell>
            )
        },
        {
            data: 'menu',
            isPadded: false,
            key: 'contextMenu',
            maxWidth: 30,
            minWidth: 30,
            name: '',
            onRender: (item: SearchResult) => (
                <IconButton
                    menuProps={{
                        items: [
                            {
                                iconProps: { iconName: 'RedEye' },
                                key: 'viewResult',
                                onClick: () => onViewRow(item),
                                text: 'View'
                            }
                        ]
                    }}
                    menuIconProps={{ style: { display: 'none' } }}
                    iconProps={{ iconName: 'More', style: { fontSize: '16px' } }}
                />
            )
        },
        {
            //MCS Reference
            data: 'string',
            isPadded: true,
            key: 'mCSReference',
            minWidth: 100,
            maxWidth: 150,
            name: 'Customer Number',
            onRender: (item: SearchResult) => <StyledTableCell>{item.mCSReference}</StyledTableCell>
        },
        {
            //MCS Reference
            data: 'string',
            isPadded: true,
            key: 'companyRegistrationNumber',
            minWidth: 120,
            maxWidth: 150,
            name: 'Company Registration',
            onRender: (item: SearchResult) => <StyledTableCell>{item.companyRegistrationNumber}</StyledTableCell>
        },
        {
            //Application date
            data: 'ISODateString',
            isPadded: true,
            key: 'applicationDate',
            minWidth: 100,
            maxWidth: 100,
            name: 'Application Date',
            onRender: (item: SearchResult) => <StyledTableCell>{renderDate(item.applicationDate)}</StyledTableCell>
        },
        {
            //Customer status
            data: 'object',
            isPadded: true,
            key: 'customerStatus',
            minWidth: 100,
            maxWidth: 100,
            name: 'Status',
            onRender: (item: SearchResult) => <StyledTableCell>{item.status.statusDescription}</StyledTableCell>
        }
    ];
}
