import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
      html {
        height: 100%;
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.fontSize};
      }

      body {
        height: 100%;
        font-family: ${theme.typography.body.fontFamily};
        font-size: ${theme.typography.body.fontSize};
        font-weight: ${theme.typography.body.fontWeight};
        line-height: ${theme.typography.body.lineHeight};
        color: ${theme.palette.text.primary};
        margin: 0;
      }
      
      b {
          font-weight: ${theme.typography.fontWeightBold};
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
      }

      h1 {
        font-family: ${theme.typography.h1.fontFamily};
        font-size: ${theme.typography.h1.fontSize};
        font-weight: ${theme.typography.h1.fontWeight};
        line-height: ${theme.typography.h1.lineHeight};
        color: ${theme.palette.text.primary};
      }

      h2 {
        font-family: ${theme.typography.h2.fontFamily};
        font-size: ${theme.typography.h2.fontSize};
        font-weight: ${theme.typography.h2.fontWeight};
        line-height: ${theme.typography.h2.lineHeight};
        color: ${theme.palette.text.primary};
      }

      label {
        font-family: ${theme.typography.label.fontFamily};
        font-size: ${theme.typography.label.fontSize};
        font-weight: ${theme.typography.label.fontWeight};
        line-height: ${theme.typography.label.lineHeight};
        color: ${theme.palette.gray[900]};
      }

      a {
        text-decoration: underline;
        color: ${theme.palette.primary.main};
        cursor: pointer;
      }

      input:focus,
      select:focus,
      textarea:focus,
      button:focus {
        outline: none;
      }

      #root {
        height: 100%;
        width: 100%;
      }

      .securityContainer {
        height: 100%;
      }
  `}
`;

export { GlobalStyle };
