import React, { memo, useLayoutEffect } from 'react';
import { isNullOrUndefined } from 'utils/validation.utils';

interface IConditionalRenderProps {
  condition: (() => boolean) | boolean;
  children: any;
  onRender?: () => void;
}

const ConditionalRender = memo((props: IConditionalRenderProps) => {
  
  const resolveCondition = (): boolean => {
    return typeof props.condition === 'boolean' ? props.condition : props.condition();
  }

  useLayoutEffect(() => {
    if(!isNullOrUndefined(props.onRender))
      props.onRender(); 
  }, [props]);

  return (
    <React.Fragment>
      {resolveCondition() && props.children}
    </React.Fragment>
  )
});

export default ConditionalRender;