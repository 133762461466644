import styled from 'styled-components';

const StyledBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    flex: 1;
    min-height: 0;
    overflow: hidden;
`;

const StyledBody = styled.div`
    flex: 1;
    min-height: 0;
    display: flex;
    overflow-x: auto;
`;

export { StyledBodyContainer, StyledBody };
