import { createTheme, Customizations, initializeIcons } from '@fluentui/react';

export const setupFluentUiTheme = (): void => {
    const fluentTheme = createTheme({
        palette: {
            themePrimary: '#008545',
            themeLighterAlt: '#f0faf5',
            themeLighter: '#c6ebd9',
            themeLight: '#99dabb',
            themeTertiary: '#49b681',
            themeSecondary: '#129355',
            themeDarkAlt: '#00773e',
            themeDark: '#006534',
            themeDarker: '#004a26',
            neutralLighterAlt: '#faf9f8',
            neutralLighter: '#f3f2f1',
            neutralLight: '#edebe9',
            neutralQuaternaryAlt: '#e1dfdd',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#a19f9d',
            neutralSecondary: '#605e5c',
            neutralPrimaryAlt: '#3b3a39',
            neutralPrimary: '#323130',
            neutralDark: '#201f1e',
            black: '#000000',
            white: '#ffffff'
        },
        semanticColors: {
            inputBorder: '#a19f9d',
            smallInputBorder: '#a19f9d'
        },
        defaultFontStyle: {
            fontFamily: '"Roboto", "Open Sans", "sans-serif", "Arial"'
        }
    });

    Customizations.applySettings({ theme: fluentTheme });
    initializeIcons();
};
