import React, { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { SearchRoutes, searchRoutes } from 'sections/search/routes/search.routes';
import SearchResults from 'sections/search/pages/results/components/SearchResults';

/**
 * SearchHome component
 */
const SearchHome: FC = () => {
    return (
        <Switch>
            <SecureRoute path={searchRoutes[SearchRoutes.Results]} component={SearchResults} />
            <Redirect to='/' />
        </Switch>
    );
};

export default SearchHome;
