import styled from 'styled-components';

export const StyledCreditLimitReviewReportPage = styled.div`
    flex: 1;
    padding: 15px;
`;

export const StyledHeaderText = styled.h1`
    margin-bottom: 25px;
    font-size: 1.9em;
`;

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledFilterBar = styled.div`
    display: flex;
    padding: 10px 15px;
    box-shadow: ${({ theme }) => theme.shadow};
    border-radius: 4px;

    > :not(:last-child) {
        margin-right: 15px;
    }
`;

export const StyledRow = styled.div`
    display: flex;
    margin: 0 -15px;
`;

export const StyledRefreshContainer = styled.div``;

export const StyledDateContainer = styled.div`
    margin-left: auto !important;
    margin-right: 15px;
    display: flex;
    align-items: center;
`;

export const StyledCreditContainer = styled.div`
    margin-left: auto !important;
    margin-right: 15px;
    display: flex;
    align-items: center;
`;
