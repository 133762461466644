import { buildRoute } from 'utils/route.utils';
import { routes as appRoutes, AppSectionRoute } from 'routes/app.routes';

const mainRoute = appRoutes[AppSectionRoute.CustomerAccounts];

export enum CustomerAccountRoute {
    Applications = 'applications',
    NewApplication = 'newApplication',
    ViewApplication = 'viewApplication',
    Accounts = 'accounts',
    ViewAccount = 'viewAccount',
    Watchlist = 'watchlist',
    NewWatchlist = 'newwatchlist',
    ViewWatchListItem = 'viewWatchListItem',
    Configuration = 'customerConfiguration',
    PerformanceDashboard = 'performanceDashboard',
    CreditLimitReport = 'creditLimitReport'
}

export const customerAccountRoutes: { [key in CustomerAccountRoute]: (id?: string) => string } = {
    [CustomerAccountRoute.Applications]: () => buildRoute(mainRoute, ['/applications']),
    [CustomerAccountRoute.NewApplication]: () => buildRoute(mainRoute, ['/applications/new']),
    [CustomerAccountRoute.ViewApplication]: id => buildRoute(mainRoute, [`/applications/${id ?? ':id'}`]),
    [CustomerAccountRoute.Accounts]: () => buildRoute(mainRoute, ['/accounts']),
    [CustomerAccountRoute.ViewAccount]: id => buildRoute(mainRoute, [`/accounts/${id ?? ':id'}`]),
    [CustomerAccountRoute.Watchlist]: () => buildRoute(mainRoute, ['/watchlist']),
    [CustomerAccountRoute.NewWatchlist]: () => buildRoute(mainRoute, ['/watchlist/new']),
    [CustomerAccountRoute.ViewWatchListItem]: id => buildRoute(mainRoute, [`/watchlist/${id ?? ':id'}`]),
    [CustomerAccountRoute.Configuration]: () => buildRoute(mainRoute, ['/configuration']),
    [CustomerAccountRoute.PerformanceDashboard]: () => buildRoute(mainRoute, ['/performancedashboard']),
    [CustomerAccountRoute.CreditLimitReport]: () => buildRoute(mainRoute, ['/creditLimitReport'])
};
