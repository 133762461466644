import styled from 'styled-components';

const StyledErrorBoundaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex: 1;
    height: 100%;

    h1 {
        font-size: 24px;
        font-weight: 600;
        padding: 15px 0 25px 0;
    }

    ul {
        list-style: disc;

        li {
            padding: 15px 0;
        }
    }
`;

export { StyledErrorBoundaryContainer };
