import React, { memo } from 'react';
import { StyledMenuBar } from '../styled/MenuBar.styled';

export interface MenuBarProps {
  children: any;
}

const MenuBar = (props: MenuBarProps) => {
  return (
    <StyledMenuBar>
      {props.children}
    </StyledMenuBar>
    );
};

MenuBar.displayName = 'MenuBar';

export default memo(MenuBar);