export {};
// eslint-disable-next-line no-extend-native
Object.defineProperties(String.prototype, {
    format: {
        configurable: false,
        enumerable: false,
        value: function format(...args: string[]) {
            let returnVal = '';

            args.forEach((val, i) => (returnVal = this.replace(`{${i}}`, args[i])));

            return returnVal;
        },
        writable: false
    }
});
