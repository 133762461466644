import { actionCreatorFactory } from 'utils/redux.utils';
import { SearchResult } from 'sections/search/pages/results/queries/search-results.queries.types';
import { ApolloError } from 'apollo-client';

export enum SearchResultsActions {
    SetPage = 'SET_PAGE',
    SetSearchQuery = 'SET_SEARCH_QUERY',
    SetSearchError = 'SET_SEARCH_ERROR',
    AddSearchResults = 'ADD_TO_SEARCH_RESULTS',
    SetFetchingResults = 'SET_FETCHING_RESULTS',
    ResetSearch = 'RESET_SEARCH'
}

export const setPage = actionCreatorFactory<typeof SearchResultsActions, number>(SearchResultsActions.SetPage);

export const setSearchQuery = actionCreatorFactory<typeof SearchResultsActions, string>(
    SearchResultsActions.SetSearchQuery
);

export const setSearchError = actionCreatorFactory<typeof SearchResultsActions, ApolloError | undefined>(
    SearchResultsActions.SetSearchError
);

export const addSearchResults = actionCreatorFactory<
    typeof SearchResultsActions,
    { searchResults: SearchResult[]; totalCount: number }
>(SearchResultsActions.AddSearchResults);

export const setFetchingResults = actionCreatorFactory<typeof SearchResultsActions, boolean>(
    SearchResultsActions.SetFetchingResults
);

export const resetSearch = actionCreatorFactory<typeof SearchResultsActions, undefined>(
    SearchResultsActions.ResetSearch
);
