export enum AppSectionRoute {
    CustomerAccounts = 'customerAccounts',
    SupplierAccounts = 'supplierAccounts',
    Search = 'search'
}

/** The app route collection */
export const routes: { [key in AppSectionRoute]: string } = {
    [AppSectionRoute.CustomerAccounts]: '/customers',
    [AppSectionRoute.SupplierAccounts]: '/suppliers',
    [AppSectionRoute.Search]: '/search'
};
