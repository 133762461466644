import styled from 'styled-components';

const StyledAppContainer = styled.div`
  ${({theme}) => `
    background-color: ${theme.palette.background.default};
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

export { StyledAppContainer };