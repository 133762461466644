import React, { useEffect, useState } from 'react';
import {
    StyledPostLogoutContainer,
    StyledLogo,
    StyledLogoContainer,
    StyledText,
    StyledTextContainer,
    StyledContainer
} from './PostLogout.styles';
import { useHistory } from 'react-router-dom';
import Button from 'components/general/Button';
import { clearPostLogout, isPostLogoutSet } from 'components/shared/App/utils/post-logout.utils';

/**
 * PostLogout component
 */
export const PostLogout = () => {
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        if (!isPostLogoutSet()) history.push('/');
        else {
            setShouldRender(true);
            clearPostLogout();
        }
    }, [history]);

    return (
        <>
            {shouldRender && (
                <StyledPostLogoutContainer>
                    <StyledContainer>
                        <StyledLogoContainer>
                            <StyledLogo />
                        </StyledLogoContainer>
                        <StyledText>
                            <StyledTextContainer>
                                You have successfully logged out, it is now safe to close your browser
                            </StyledTextContainer>
                            <StyledTextContainer>
                                <Button text='Login' onClick={() => history.push('/')} />
                            </StyledTextContainer>
                        </StyledText>
                    </StyledContainer>
                </StyledPostLogoutContainer>
            )}
        </>
    );
};
