import { gql } from 'graphql.macro';

export const GetCustomerSuggestions = gql`
    query customerSearch($search: SearchModelInput) {
        customerSearch(search: $search) {
            customers {
                address {
                    line1
                    line2
                    line3
                    line4
                    postCode
                }
                applicantName
                customerId
                mCSReference
                status {
                    statusDescription
                    statusId
                    statusName
                }
            }
        }
    }
`;
