import React from 'react';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import AppContainer from 'components/shared/App/components/AppContainer';
import { Route, Switch } from 'react-router-dom';
import { AuthRoute, routes as authRoutes } from 'routes/auth.routes';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import { PostLogout } from 'components/shared/App/components/PostLogout';

/**
 * AppSecurity component
 */
export const AppSecurityRouter = () => {
    const { settings } = useConfig<IAppConfig>();

    return (
        <Switch>
            <Route path={authRoutes[AuthRoute.PostLogout]()} component={PostLogout} />
            <Route path={authRoutes[AuthRoute.ImplicitCallback](settings)} component={LoginCallback} />
            <SecureRoute path={'/'} component={AppContainer} />
        </Switch>
    );
};
