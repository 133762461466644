import React, { FC } from 'react';
import { IconButton } from '@fluentui/react';
import TextInput from 'components/general/TextInput';
import { SearchEvent } from './SearchInput.types';

/**
 * Props interface for a search input component
 */
interface ISearchInputProps {
    /**
     * Whether it is a required field
     */
    required?: boolean;

    /**
     * Error message to display on the component
     */
    errorMessage?: string;

    /**
     * Whether the component is disabled
     */
    disabled?: boolean;

    /**
     * If true, the search button and search functionality will be disabled
     */
    searchDisabled?: boolean;

    /**
     * The value to display in the input
     */
    value: string;

    /**
     * The callback fired when the value of the search input is changed
     * @param event The triggered event
     * @param newValue The new input value
     */
    onChange: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
        name?: string
    ) => void;

    /**
     * The callback fired when a search is triggered either by pressing enter with the input focused,
     * or by clicking the search button
     * @param event The triggered event
     * @param searchValue The search value present in the input when the search event was triggered
     */
    onSearch: (event: SearchEvent, searchValue?: string) => void;

    /**
     * Fired when the search is focused
     */
    onFocus?: () => void;

    /**
     * Fired when the text input is exited
     */
    onBlur?: () => void;

    /**
     * The input label
     */
    label?: string;

    /**
     * The input placeholder
     */
    placeholder?: string;

    /**
     * If supplied, this name will be returned in the name argument of the onChange event
     * @see ISearchInputProps#onChange
     */
    name?: string;

    /**
     * Displayed before the input
     */
    prefix?: string;

    /**
     * A static identifier used to uniquely identify the component for automation testing
     */
    testIdentifier: string;

    /**
     * The tooltip
     */
    title?: string;

    /**
     * Sets the focus of the input element
     */
    autoFocus?: boolean;
}

/**
 * A text input component {@link TextInput} with a search capability
 * @param props The component props {@link ISearchInputProps}
 */
const SearchInput: FC<ISearchInputProps> = ({
    required,
    errorMessage,
    disabled,
    searchDisabled,
    value,
    onChange,
    onSearch,
    onFocus,
    onBlur,
    label,
    placeholder,
    name,
    prefix,
    testIdentifier,
    title,
    autoFocus
}: ISearchInputProps) => {
    /**
     * IButtonProps#onChange callback - Triggers the onSearch callback from props on click
     * @param event The click event
     * @see {@link IconButton} from Fluent UI
     */
    function handleSearchOnClick(event: React.MouseEvent<HTMLButtonElement>): void {
        if (!searchDisabled) onSearch(event, value);
    }

    /**
     * {@link ITextInputProps#onKeyDown} callback - Triggers the onSearch callback from props if the key down is enter
     * @param event The key press event
     * @see {@link TextInput} from Fluent UI
     */
    function handleSearchOnKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        if (event.keyCode === 13 && !searchDisabled) onSearch(event, value);
    }

    return (
        <div data-testing-id={`${testIdentifier}-si`}>
            <TextInput
                title={title}
                testIdentifier={`${testIdentifier}-si`}
                prefix={prefix}
                autoComplete='off'
                required={required}
                errorMessage={errorMessage}
                disabled={disabled}
                value={value}
                label={label}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                onKeyDown={handleSearchOnKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                styles={{
                    suffix: { padding: 'none', overflow: 'hidden', cursor: 'pointer', background: 'transparent' }
                }}
                autoFocus={autoFocus}
                onRenderSuffix={() => (
                    <IconButton
                        data-testing-id={`${testIdentifier}-si-sb`}
                        disabled={disabled || searchDisabled}
                        iconProps={{ iconName: 'Search' }}
                        onClick={handleSearchOnClick}
                        styles={{
                            root: {
                                background: 'transparent'
                            },
                            rootDisabled: {
                                background: 'rgb(243, 242, 241)'
                            },
                            rootHovered: {
                                background: 'transparent',
                                color: '#008545'
                            },
                            rootPressed: {
                                background: 'transparent'
                            }
                        }}
                    />
                )}
            />
        </div>
    );
};

export default SearchInput;
