import React, { ComponentType } from 'react';
import { ToastContainerProps } from 'react-toast-notifications';

const placements = {
    'top-left': { top: 0, left: 0 },
    'top-center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
    'top-right': { top: 0, right: 0 },
    'bottom-left': { bottom: 0, left: 0 },
    'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
    'bottom-right': { bottom: 0, right: 0 }
};

const gutter = 8;

export const ToastContainer: ComponentType<ToastContainerProps> = ({
    hasToasts,
    placement,
    ...props
}: ToastContainerProps) => (
    <div
        className='react-toast-notifications__container'
        style={{
            boxSizing: 'border-box',
            maxHeight: '100%',
            overflow: 'hidden',
            padding: gutter,
            pointerEvents: hasToasts ? undefined : 'none',
            position: 'fixed',
            zIndex: 999999999,
            ...placements[placement]
        }}
        {...props}
    />
);
