import React, { FC, useEffect, useReducer, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SearchQuery } from 'sections/search/pages/results/index';
import { isUndefined } from 'utils/validation.utils';
import { createLoadingContext } from 'utils/loading/context/loading.context';
import { SearchResultsLoading } from 'sections/search/pages/results/enums/SearchResultsLoading';
import { initialSearchResultsLoading } from 'sections/search/pages/results/options/results-loading.options';
import { withLoadingContext } from 'utils/loading/hocs/withLoadingContext';
import { useApolloClient } from '@apollo/react-hooks';
import {
    GetCustomerSearchResultsResponse,
    GetCustomerSearchResultsVariables,
    SearchResult
} from 'sections/search/pages/results/queries/search-results.queries.types';
import { GetCustomerSearchResults } from 'sections/search/pages/results/queries/search-results.queries';
import { useToasts } from 'react-toast-notifications';
import { PageHeader } from 'components/shared/PageHeader';
import { VirtualTable } from 'components/general/NewTable/Table';
import { getColumns } from 'sections/search/pages/results/options/results-table.options';
import { StyledTableContainer } from 'sections/search/pages/results/components/SearchResults.styles';
import { NoData } from 'components/shared/NoData';
import GraphQlErrorBoundary from 'components/shared/ErrorBoundary';
import { searchResultsStateReducer } from 'sections/search/pages/results/store/reducer';
import { defaultSearchResultsState } from 'sections/search/pages/results/store/state';
import {
    addSearchResults,
    resetSearch,
    setFetchingResults,
    setPage,
    setSearchError,
    setSearchQuery
} from 'sections/search/pages/results/store/actions';
import { SearchMode } from 'types/gateway/cognitive-search.enums';
import { getViewUrl } from 'sections/search/pages/results/utils/search-results.utils';
import { v4 as uuid } from 'uuid';
import Loading from 'components/shared/Loading';

const searchResultLoadingContext = createLoadingContext<SearchResultsLoading>();

/**
 * SearchResults component
 */
const SearchResults: FC = () => {
    const location = useLocation();
    const apolloClient = useApolloClient();
    const history = useHistory();
    const { addToast } = useToasts();
    const [state, dispatch] = useReducer(searchResultsStateReducer, defaultSearchResultsState);
    const currentQuery = useRef<UID | null>();

    /**
     * Set search values from query string
     */
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has(SearchQuery.Query)) dispatch(setSearchQuery(searchParams.get(SearchQuery.Query) ?? ''));

        dispatch(resetSearch());
    }, [location]);

    /**
     * Loads the search results from the search query and type
     */
    useEffect(() => {
        if (state.searchQuery.length < 3) {
            dispatch(setFetchingResults(false));
            return;
        }

        dispatch(setFetchingResults(true));

        //used to ensure only the latest query updates the list
        const query = uuid();
        currentQuery.current = query;

        apolloClient
            .query<GetCustomerSearchResultsResponse, GetCustomerSearchResultsVariables>({
                query: GetCustomerSearchResults,
                variables: {
                    search: {
                        searchText: state.searchQuery,
                        currentPage: state.currentPage,
                        resultsPerPage: state.resultsPerPage,
                        filterGroup: null,
                        orderBy: null,
                        searchMode: SearchMode.All
                    }
                },
                fetchPolicy: 'network-only'
            })
            .then(response => {
                if (currentQuery.current !== query) return;

                const searchResults = response.data.customerSearch?.customers ?? [];
                const totalRecordCount = response.data.customerSearch?.recordCount ?? 0;

                dispatch(
                    addSearchResults({
                        searchResults: searchResults,
                        totalCount: totalRecordCount
                    })
                );
            })
            .catch(error => {
                dispatch(setSearchError(error));
            })
            .finally(() => {
                dispatch(setFetchingResults(false));
            });
    }, [state.searchQuery, state.currentPage, state.resultsPerPage, apolloClient, addToast]);

    /**
     * Returns the label text to display on the no data component
     */
    const getNoDataLabel = (): string => {
        return state.searchQuery.length < 3
            ? 'Search term must be 3 characters or more'
            : `No results found for "${state.searchQuery}"`;
    };

    /**
     * Redirects to the relevant view page depending on the search result type
     * @param {SearchResult} result The search result
     */
    const handleView = (result?: SearchResult | null): void => {
        const url = getViewUrl(result);

        if (!isUndefined(url)) history.push(url);
    };

    /**
     * Handles a table row being mounted, if the row is the last in the table, and there are more results
     * The next page of results will be loaded
     * @param {SearchResult} result The search result for the table row that has mounted
     * @param {number} index The index of the mounted table row
     */
    const handleMount = (result?: SearchResult, index?: number) => {
        if (
            index === state.searchResults.length - 1 &&
            state.totalResultsCount > state.searchResults.length &&
            !state.fetchingResults
        )
            dispatch(setPage(state.currentPage + 1));
    };

    return (
        <StyledTableContainer>
            <PageHeader>Search Results</PageHeader>
            <GraphQlErrorBoundary error={state.searchError}>
                <Loading
                    isLoading={state.searchResults.length === 0 && state.fetchingResults}
                    message={`Searching for results like '${state.searchQuery}'`}
                >
                    <NoData
                        hasData={!(state.searchResults.length === 0 && !state.fetchingResults)}
                        label={getNoDataLabel()}
                        iconName='SearchIssue'
                    >
                        <VirtualTable<SearchResult>
                            items={state.searchResults}
                            columns={getColumns(handleView)}
                            onRowClick={handleView}
                            keyFieldName='customerId'
                            onRowDidMount={handleMount}
                        />
                    </NoData>
                </Loading>
            </GraphQlErrorBoundary>
        </StyledTableContainer>
    );
};

SearchResults.displayName = 'SearchResults';

export default withLoadingContext(SearchResults, initialSearchResultsLoading, searchResultLoadingContext);
