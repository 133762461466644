import React, { createContext } from 'react';
import { RestClient } from '../RestClient';

const RestContext = createContext<RestClient | undefined>(undefined);

interface IRestProviderProps<TEndPoints> {
  children: React.ReactElement;
  client: RestClient<TEndPoints>;
}

const RestProvider = <TEndPoints,>({ children, client }: IRestProviderProps<TEndPoints>) => {
  return (
    <RestContext.Provider value={client}>
      {children}
    </RestContext.Provider>
  )
}

export default RestProvider;
export { RestContext };