/** The application statuses split into the relevant types */
import { WatchlistStatusEnum } from 'sections/customer-accounts/shared/enums/WatchlistStatus.enum';
import { ApplicationStatusEnum } from 'sections/customer-accounts/shared/enums/ApplicationStatus.enum';
import { CustomerStatusEnum } from 'sections/customer-accounts/shared/enums/CustomerStatus.enum';

type CustomerStatusTypes = {
    Application: ApplicationStatusEnum[];
    Watchlist: WatchlistStatusEnum[];
    Customer: CustomerStatusEnum[];
};

export const applicationTypes: CustomerStatusTypes = {
    Application: [
        ApplicationStatusEnum.CreditApproval,
        ApplicationStatusEnum.Cancelled,
        ApplicationStatusEnum.Declined,
        ApplicationStatusEnum.CreditCheck,
        ApplicationStatusEnum.New,
        ApplicationStatusEnum.Pending,
        ApplicationStatusEnum.IdVerification,
        ApplicationStatusEnum.IdVerificationAccepted,
        ApplicationStatusEnum.IdVerificationRejected
    ],
    Watchlist: [WatchlistStatusEnum.Active, WatchlistStatusEnum.Removed],
    Customer: [CustomerStatusEnum.Closed, CustomerStatusEnum.Open, CustomerStatusEnum.Stop, CustomerStatusEnum.Refer]
};
