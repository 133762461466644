import React from 'react';
import {
    StyledCustomerSuggestionItemContainer,
    StyledCustomerSuggestionPrimary,
    StyledCustomerSuggestionSecondary,
    StyledFontIcon,
    StyledInformationContainer,
    StyledIconContainer,
    StyledCustomerNumber
} from './CustomerSuggestionItem.styles';
import { isNullOrEmpty } from 'utils/validation.utils';
import { joinValues } from 'utils/format.utils';
import { CustomerSuggestion } from 'components/shared/Header/queries/header.queries.types';
import { theme } from 'themes/aplant.theme';
import { isApplicationItem, isCustomerItem, isWatchlistItem } from 'components/shared/Header/utils/header.utils';

interface ICustomerSuggestionItemProps {
    item: CustomerSuggestion;
    onClick: (customer: CustomerSuggestion) => void;
}

const CustomerSuggestionItem = ({ item, onClick }: ICustomerSuggestionItemProps) => {
    return (
        <StyledCustomerSuggestionItemContainer onClick={() => onClick(item)}>
            <StyledIconContainer>
                {isApplicationItem(item) ? (
                    <StyledFontIcon iconName='AddFriend' title='Customer Application' />
                ) : isCustomerItem(item) ? (
                    <StyledFontIcon iconName='Contact' title='Customer' />
                ) : isWatchlistItem(item) ? (
                    <StyledFontIcon
                        iconName='ShieldAlert'
                        style={{ color: theme.palette.error.main }}
                        title='Watchlist'
                    />
                ) : (
                    <StyledFontIcon iconName='StatusCircleQuestionMark' title='Unknown' />
                )}
            </StyledIconContainer>
            <StyledInformationContainer>
                <StyledCustomerSuggestionPrimary>
                    {item.applicantName}
                    {!isNullOrEmpty(item.mCSReference) && (
                        <StyledCustomerNumber>{`(${item.mCSReference})`}</StyledCustomerNumber>
                    )}
                </StyledCustomerSuggestionPrimary>

                <StyledCustomerSuggestionSecondary style={{ fontStyle: 'italic' }}>
                    {joinValues([item.address.line1, item.address.line3, item.address.line4, item.address.postCode])}
                </StyledCustomerSuggestionSecondary>
            </StyledInformationContainer>
        </StyledCustomerSuggestionItemContainer>
    );
};

export default CustomerSuggestionItem;
