import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuBarLinkProps } from '../components/MenuBarLink';

const StyledMenuBarLink =  styled(Link)<MenuBarLinkProps>`
  ${({theme, invert, disabled}) => `
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: ${theme.typography.button.fontSize};
    font-weight: ${theme.typography.button.fontWeight};
    line-height: ${theme.typography.button.lineHeight};
    font-family: ${theme.typography.button.fontFamily};

    padding: 0 5px;
    white-space: nowrap;
    text-decoration: none;

    color: ${invert 
      ? theme.palette.common.white 
      : theme.palette.gray[800]};

    background: ${theme.palette.transparent};

    ${disabled ? `
      color: ${theme.palette.text.disabled};

      :hover {
        cursor: default;
      }
    ` : ''}

    ${!disabled ? `
      :hover {
        color: ${invert
          ? theme.palette.secondary.main
          : theme.palette.action.hover};
    ` : ''}
  `}
`;

export { StyledMenuBarLink };