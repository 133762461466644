import React, { CSSProperties } from 'react';
import { StyledSideMenuAction, StyledIcon, StyledLabel } from '../styled/SideMenu.styled';

interface SideMenuActionProps {
  icon: any;
  label: string;
  title?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: CSSProperties;
}

const SideMenuAction = ({
  icon,
  label,
  onClick,
  title,
  style
}: SideMenuActionProps) => {
  return (
    <StyledSideMenuAction style={style} title={title} onClick={onClick}>
      <StyledIcon>{icon}</StyledIcon>
      <StyledLabel>{label}</StyledLabel>
    </StyledSideMenuAction>
  );
};

export default SideMenuAction;