import { gql } from 'graphql.macro';

export const DownloadOriginalApplication = gql`
    mutation DownloadOriginalApplication($request: ReasonForDownloadRequestInput){
      downloadOriginalApplication(request: $request)
    }
`;

export const UpdateDownloadDestroyed = gql`
    mutation UpdateDownloadDestroyed($request: DownloadDestroyedRequestInput){
        updateDownloadDestroyed(request: $request)
    }
`;