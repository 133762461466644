import { ITextFieldStyles } from '@fluentui/react';

export const style = (readOnly: boolean): Partial<ITextFieldStyles> => {
    const fieldGroup = readOnly
        ? {
              borderColor: 'transparent',
              selectors: {
                  '::after': {
                      borderColor: 'transparent'
                  },
                  ':hover': {
                      borderColor: 'transparent'
                  }
              }
          }
        : undefined;

    const field = readOnly
        ? {
              selectors: {
                  cursor: 'default'
              }
          }
        : undefined;

    return {
        field: field,
        fieldGroup: fieldGroup,
        root: {
            flex: '1 1 auto'
        }
    };
};
