import React from 'react';
import { Error } from 'styled-icons/boxicons-regular';
import { isNullOrUndefined } from 'utils/validation.utils';
import { ApolloError } from 'apollo-client';
import { StyledErrorBoundaryContainer } from '../styled/GraphQlErrorBoundary.styled';
import { getErrorMessages } from 'utils/errors/errors.utils';

interface GraphQlErrorHandlerProps {
  error?: ApolloError;
  children: any;
}



const GraphQlErrorBoundary = ({error, children}: GraphQlErrorHandlerProps) => {

  return (
    <React.Fragment>
      {!isNullOrUndefined(error)
      ?
        <StyledErrorBoundaryContainer>
          <Error style={{color: '#FED203'}} size="100px" />
          <h1>Oops!</h1>
          <ul>
            {getErrorMessages(error).map((errorMessage, index) => 
              <li key={index}>{errorMessage}</li>  
            )}
          </ul>
        </StyledErrorBoundaryContainer>
      : 
        children
      }
    </React.Fragment>
  )
}

export default GraphQlErrorBoundary;