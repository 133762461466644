import React, { CSSProperties, memo, useCallback } from 'react';
import {
    DirectionalHint,
    FontIcon,
    getTheme,
    ICalloutContentStyles,
    IComboBox,
    IComboBoxOption,
    ITheme,
    Label,
    VirtualizedComboBox
} from '@fluentui/react';
import {
    StyledLoadingMessage,
    StyledSelectContainer,
    StyledSelectOverlay,
    StyledIconContainer,
    StyledSelectFieldIcon
} from 'components/general/Select/styled/Select.styled';
import { ReactComponent as Logo } from 'assets/Images/Sunbelt.svg';
import { RequiredPermission } from 'utils/permissions/types/RequiredPermission';
import { usePermissions } from 'utils/permissions/hooks/usePermissions';
import { ReadOnlyField } from 'components/general/ReadOnlyField';

interface IComboSelectProps {
    disabled?: boolean;
    style?: CSSProperties;
    label?: string;
    required?: boolean;
    allowFreeform?: boolean;
    autoComplete?: boolean;
    options: IComboBoxOption[];
    placeholder?: string;
    transparent?: boolean;
    borderless?: boolean;
    multiSelect?: boolean;
    calloutWidth?: 'auto' | 'fixed';
    errorMessage?: string;
    showIcon?: boolean;
    iconSource?: string;
    iconOnClick?: () => void;
    iconDeleteOnClick?: () => void;
    showDeleteIcon?: boolean;
    onChange?: (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        index?: number | undefined,
        value?: string | undefined
    ) => void;
    selectedKey?: string | number;
    text?: string;
    onPendingValueChanged?: (
        option?: IComboBoxOption | undefined,
        index?: number | undefined,
        value?: string | undefined
    ) => void;
    isLoading?: boolean;
    loadingMessage?: string;
    optionsError?: boolean;
    readPermissions?: RequiredPermission[];
    writePermissions?: RequiredPermission[];
    readonly?: boolean;
}

const styles = (props: Partial<IComboSelectProps>, theme: ITheme) => {
    return {
        container: {
            flex: '1',
            selectors: {
                ':focus::after': {
                    border: props.borderless ? 'none' : undefined
                }
            }
        },
        root: {
            background: props.transparent ? 'transparent' : undefined,
            border: props.borderless ? 'none' : undefined,
            selectors: {
                ':hover': {
                    background: props.transparent ? theme.palette.neutralLighter : undefined
                },
                '::after': {
                    border: props.borderless ? 'none' : undefined
                }
            }
        },
        input: {
            background: props.transparent ? 'transparent' : undefined
        }
    };
};

const calloutStyles = ({ calloutWidth = 'fixed' }: IComboSelectProps) => (): Partial<ICalloutContentStyles> => {
    if (calloutWidth === 'auto') {
        return {
            root: {
                width: 'auto !important'
            }
        };
    }

    return {};
};

const ComboSelect = memo((props: IComboSelectProps) => {
    const { hasPermissions } = usePermissions();
    const placeholder = props.optionsError ? 'Could not load data...' : 'Select an option...';
    const theme = getTheme();

    const getValue = useCallback((): string => {
        const value = props.options.find(x => x.key === props.selectedKey);

        return hasPermissions(props.readPermissions) ? value?.text ?? '' : '************';
    }, [props.options, props.selectedKey, hasPermissions, props.readPermissions]);

    return (
        <React.Fragment>
            {props.label && <Label>{props.label}</Label>}
            <StyledSelectContainer>
                {props.isLoading && !props.optionsError && (
                    <StyledSelectOverlay>
                        <Logo
                            style={{
                                animation: 'spin 3s linear infinite',
                                height: '22px',
                                minHeight: '22px',
                                minWidth: '22px',
                                width: '22px'
                            }}
                        />
                        {props.loadingMessage && <StyledLoadingMessage>{props.loadingMessage}...</StyledLoadingMessage>}
                    </StyledSelectOverlay>
                )}

                {props.readonly && <ReadOnlyField text={getValue()} />}

                {!props.readonly && !hasPermissions(props.writePermissions) && (
                    <ReadOnlyField text={getValue()} locked />
                )}

                {!props.readonly && hasPermissions(props.writePermissions) && (
                    <>
                        <VirtualizedComboBox
                            disabled={props.disabled || props.optionsError}
                            style={props.style}
                            onPendingValueChanged={props.onPendingValueChanged}
                            required={props.required}
                            styles={styles(props, theme)}
                            allowFreeform={props.allowFreeform}
                            autoComplete={props.autoComplete ? 'on' : 'off'}
                            options={props.options}
                            placeholder={props.placeholder || placeholder}
                            useComboBoxAsMenuWidth
                            multiSelect={props.multiSelect}
                            errorMessage={props.errorMessage}
                            text={props.text}
                            onChange={props.onChange}
                            selectedKey={props.selectedKey}
                            calloutProps={{
                                directionalHint: DirectionalHint.bottomLeftEdge,
                                styles: calloutStyles(props)
                            }}
                        />
                        <StyledIconContainer>
                            {props.showDeleteIcon && <StyledSelectFieldIcon>
                                <span onClick={props.iconDeleteOnClick} style={{ cursor: 'pointer' }} title='Click to remove'>
                                    <FontIcon iconName={'Blocked2Solid'} />
                                </span>
                            </StyledSelectFieldIcon>}
                            {props.showIcon && <StyledSelectFieldIcon>
                                <span onClick={props.iconOnClick} style={{ cursor: 'pointer' }} title='Click to edit'>
                                    <FontIcon iconName={props.iconSource} />
                                </span>
                            </StyledSelectFieldIcon>}
                        </StyledIconContainer>
                    </>
                )}
            </StyledSelectContainer>
        </React.Fragment>
    );
});

export default ComboSelect;
