import React from 'react';
import { StyledMainContainer } from '../styled/Main.styled';

interface MainProps {
  children: any;
}

const Main = (props: MainProps) => {
  return (
    <StyledMainContainer>
      {props.children}
    </StyledMainContainer>
  );
}

export default Main;