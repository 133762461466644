import React, { useMemo } from 'react';
import { DatePicker, ITheme, ITextFieldProps, ITextFieldStyles, getTheme, IconButton } from '@fluentui/react';
import { isNullOrUndefined } from 'util';

interface IDateInputProps {
    transparent?: boolean;
    borderless?: boolean;
    placeholder?: string;
    onDateSelect: (date: Date | null | undefined) => void;
    value?: string;
    restrictWidth?: boolean;
    minDate?: Date | string;
    maxDate?: Date | string;
    label?: string;
    isRequired?: boolean;
    disabled?: boolean;
    nullable?: boolean;
}

const styles = (props: IDateInputProps, theme: ITheme) => (
    datePickerProps: ITextFieldProps
): Partial<ITextFieldStyles> => {
    return {
        fieldGroup: {
            maxWidth: props.restrictWidth ? '160px' : undefined,
            minWidth: '160px',
            background: props.transparent ? 'transparent' : undefined,
            selectors: {
                ':hover': {
                    background: props.transparent ? theme.palette.neutralLighter : undefined
                }
            }
        },
        suffix: {
            paddingLeft: '4px',
            paddingRight: '0',
            background: 'transparent'
        },
        field: {
            paddingLeft: '38px',
            paddingRight: '8px'
        }
    };
};

const parseDate = (date: Date | string | undefined): Date | undefined => {
    if (isNullOrUndefined(date)) return date;

    if (typeof date === 'string') return new Date(date);

    return date;
};

const DateInput = (props: IDateInputProps) => {
    const theme = getTheme();

    const getDate = useMemo(() => (props.value ? new Date(props.value) : undefined), [props.value]);

    return (
        <DatePicker
            disabled={props.disabled}
            isRequired={props.isRequired}
            borderless={props.borderless}
            textField={{
                onRenderSuffix: props.nullable
                    ? () => (
                          <IconButton
                              iconProps={{ iconName: 'Clear' }}
                              onClick={() => props.onDateSelect(null)}
                              styles={{
                                  root: {
                                      color: theme.palette.neutralPrimaryAlt
                                  },
                                  rootHovered: {
                                      background: 'transparent'
                                  },
                                  rootPressed: {
                                      background: 'transparent'
                                  },
                                  icon: {
                                      fontSize: '12px'
                                  }
                              }}
                          />
                      )
                    : undefined,
                styles: styles(props, theme)
            }}
            formatDate={(date?: Date): string => date?.toLocaleDateString() || ''}
            highlightCurrentMonth
            highlightSelectedMonth
            placeholder={props.placeholder}
            onSelectDate={props.onDateSelect}
            value={getDate}
            styles={{
                icon: {
                    left: '9px',
                    right: 'unset'
                }
            }}
            minDate={parseDate(props.minDate)}
            maxDate={parseDate(props.maxDate)}
            isMonthPickerVisible={false}
            label={props.label}
            showGoToToday={false}
        />
    );
};

export default DateInput;
