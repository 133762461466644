import { routes, AppSectionRoute } from 'routes/app.routes';
import { buildRoute } from 'utils/route.utils';

export enum SearchRoutes {
    Results = 'customerAccounts'
}

const baseRoute = routes[AppSectionRoute.Search];

/** The app route collection */
export const searchRoutes: { [key in SearchRoutes]: string } = {
    [SearchRoutes.Results]: buildRoute(baseRoute, ['/results'])
};
