import { isNullOrUndefined, isNullOrEmpty } from './validation.utils';
import { IFile } from 'types/shared/IFile';
import { DocumentType } from 'sections/customer-accounts/shared/enums/DocumentType.enum';
import { IAppConfig } from 'config/types/IAppConfig';

/**
 * Maps a supplied list of entities to a list of  the dropdown type entities <OutputType>
 * @param input The input entities
 * @param keyResolver A function to resolve the key value of the dropdown entity
 * @param valueResolver A function to resolve the display value of the dropdown entity
 */
export function mapToDropdownOptions<InputType, OutputType extends { key: string | number; text: string; data?: any }>(
    input: InputType[] | undefined,
    keyResolver: (input: InputType) => string | number,
    valueResolver: (input: InputType) => string,
    dataResolver?: (input: InputType) => any
): OutputType[] {
    if (isNullOrUndefined(input)) return [];

    return input.map(
        x =>
        ({
            key: keyResolver(x),
            text: valueResolver(x),
            data: isNullOrUndefined(dataResolver) ? undefined : dataResolver(x)
        } as OutputType)
    );
}

/**
 * Maps a file object to a form data object ready to be passed to the upload file api.
 * @param file The file object.
 * @param folderName The name of the folder to upload the file to.
 */
export function mapFileToFormData<TData extends Object>(
    file: IFile,
    appSettings: IAppConfig,
    folderName?: string,
    data?: TData
): FormData {
    let formData = new FormData();
    formData.append('appServiceName', appSettings.FileUploadAppService);
    formData.append('storageContainerName', appSettings.FileUploadContainer);
    formData.append('createdById', file.uploadedById.toString());
    formData.append('file', file.file!);
    formData.append('fileName', file.name);
    formData.append('uniqueId', file.id);
    formData.append('comment', file.comment ?? '');
    formData.append('documentType', (file.type?.id ?? DocumentType.Other).toString());

    if (!isNullOrEmpty(folderName)) formData.append('folderName', folderName);

    if (!isNullOrUndefined(data)) {
        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined) formData.append(key, value);
        });
    }

    return formData;
}
