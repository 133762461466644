export {};
// eslint-disable-next-line no-extend-native
Object.defineProperties(Number.prototype, {
    toCurrencyString: {
        configurable: false,
        enumerable: false,
        value: function toCurrencyString(symbol: string) {
            return `${symbol}${this.toLocaleString()}`;
        },
        writable: false
    }
});
