import React, { useRef, useState } from 'react';
import {
    StyledAccountContainer,
    StyledBrowserWarning,
    StyledHeaderContainer,
    StyledSearchContainer,
    StyledTitleContainer
} from '../styled/Header.styled';
import { MenuBar, MenuBarSelect } from 'components/general/MenuBar';
import { ReactComponent as Logo } from 'assets/Images/logo.svg';
import { isInternetExplorer } from 'utils/browser.utils';

import { Link, useHistory } from 'react-router-dom';
import { SearchInput } from 'components/general/SearchInput';
import { SearchQuery } from 'sections/search/pages/results';
import { SearchRoutes, searchRoutes } from 'sections/search/routes/search.routes';
import { isNullOrEmpty, isUndefined } from 'utils/validation.utils';
import { useToasts } from 'react-toast-notifications';
import { SearchEvent } from 'components/general/SearchInput/components/SearchInput.types';
import { useDebounce } from 'hooks/useDebounce';
import { v4 as uuid } from 'uuid';
import { useApolloClient } from '@apollo/react-hooks';
import {
    CustomerSuggestion,
    GetCustomerSuggestionsResponse,
    GetCustomerSuggestionsVariables
} from 'components/shared/Header/queries/header.queries.types';
import { GetCustomerSuggestions } from 'components/shared/Header/queries/header.queries';
import { SearchMode } from 'types/gateway/cognitive-search.enums';
import Loading from 'components/shared/Loading';
import CustomerSuggestionsCallout from 'components/shared/Header/components/CustomerSuggestionsCallout';
import { useElementSize } from 'hooks/useElementSize';
import { DirectionalHint } from '@fluentui/react';
import { getViewUrl } from 'components/shared/Header/utils/header.utils';
import CustomerSuggestionItem from 'components/shared/Header/components/CustomerSuggestionItem';
import { useSecurity } from 'utils/security/hooks/useSecurity';
import { Persona, PersonaSize } from '@fluentui/react';
import { theme } from 'themes/aplant.theme';
import { setPostLogout } from 'components/shared/App/utils/post-logout.utils';

const Header = () => {
    const { user, signOut } = useSecurity();
    const containerRef = useRef<HTMLDivElement>(null);
    const { width: inputWidth } = useElementSize<HTMLDivElement>(containerRef?.current);
    const { addToast } = useToasts();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const suggestionQueryId = useRef<UID | undefined>();
    const apolloClient = useApolloClient();
    const [suggestionResults, setSuggestionResults] = useState<CustomerSuggestion[]>([]);

    /**
     * Loads the suggestions based on the supplied search value
     * @param {string} searchValue The search value
     */
    const loadSuggestions = async (searchValue: string) => {
        if (searchValue.length < 3) {
            suggestionQueryId.current = undefined;
            setSuggestionResults([]);
            setIsOpen(false);
            setIsLoading(false);

            return;
        }

        const queryId = uuid();
        suggestionQueryId.current = queryId;

        setIsLoading(true);
        setIsOpen(true);

        apolloClient
            .query<GetCustomerSuggestionsResponse, GetCustomerSuggestionsVariables>({
                query: GetCustomerSuggestions,
                variables: {
                    search: {
                        searchText: searchValue,
                        currentPage: 0,
                        resultsPerPage: 5,
                        searchMode: SearchMode.All,
                        orderBy: null,
                        filterGroup: null
                    }
                }
            })
            .then(response => {
                if (suggestionQueryId.current === queryId)
                    setSuggestionResults(response.data.customerSearch?.customers ?? []);
            })
            .catch(() => null)
            .finally(() => setIsLoading(false));
    };

    /**
     * A debounced search value, the debounced function triggers the suggestions to load
     */
    const [searchValue, setSearchValue] = useDebounce<string | undefined>(async (newValue?: string) => {
        await loadSuggestions(newValue ?? '');
    }, 200);

    /**
     * Handles a search being triggered
     * @param {SearchEvent} event The triggered event
     * @param {string | undefined} searchValue The search value
     */
    const handleSearch = (event: SearchEvent, searchValue?: string) => {
        if (isNullOrEmpty(searchValue) || searchValue.length < 3) {
            addToast('Search term too short', { appearance: 'warning' });
            return;
        }

        const params = new URLSearchParams({
            [SearchQuery.Query]: searchValue
        });

        setSearchValue('');
        history.push({ pathname: searchRoutes[SearchRoutes.Results], search: params.toString() });
    };

    /**
     * Handles a suggestion being clicked and redirects to the relevant view page for the suggestion
     * @param {CustomerSuggestion} result The customer suggestion clicked
     */
    const handleView = (result?: CustomerSuggestion | null): void => {
        const url = getViewUrl(result);

        if (!isUndefined(url)) {
            history.push(url);
            setIsOpen(false);
        }
    };

    /**
     * Handles the search input being focused and displays the results if there are any present
     */
    const handleFocus = () => {
        if (suggestionResults.length > 0 || isLoading) setIsOpen(true);
    };

    const handleSignOut = async () => {
        setPostLogout();
        await signOut();
    };

    return (
        <React.Fragment>
            {isInternetExplorer() && (
                <StyledBrowserWarning>
                    For a better experience we do not recommend using Internet Explorer for this application.
                </StyledBrowserWarning>
            )}
            <StyledHeaderContainer>
                <StyledTitleContainer>
                    <Link to='/'>
                        <Logo
                            style={{ height: '40px', width: '132px' }}
                            title='Sunbelt Rentals UK - Account Management'
                        />
                    </Link>
                </StyledTitleContainer>
                <StyledSearchContainer>
                    <div ref={containerRef}>
                        <SearchInput
                            title='Customer Name, Customer Number, Company Registration Number or Application Reference'
                            testIdentifier='general-search'
                            value={searchValue ?? ''}
                            onChange={(event, newValue) => setSearchValue(newValue)}
                            onSearch={handleSearch}
                            onFocus={handleFocus}
                            placeholder='Find a Customer or Customer Application'
                        />
                    </div>
                    {isOpen && (
                        <CustomerSuggestionsCallout
                            calloutWidth={inputWidth}
                            target={containerRef}
                            onDismiss={() => setIsOpen(false)}
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            directionalHintFixed={true}
                        >
                            <Loading isLoading={isLoading} overlay={suggestionResults.length > 0} noDelay>
                                {suggestionResults.map(x => (
                                    <CustomerSuggestionItem item={x} key={x.customerId} onClick={handleView} />
                                ))}
                            </Loading>
                        </CustomerSuggestionsCallout>
                    )}
                </StyledSearchContainer>
                <StyledAccountContainer>
                    <MenuBar>
                        <Persona
                            text={user?.name}
                            size={PersonaSize.size32}
                            hidePersonaDetails
                            initialsColor={theme.palette.secondary.main}
                            title={user?.name}
                        />
                        <MenuBarSelect invert>
                            <span onClick={handleSignOut}>Logout</span>
                        </MenuBarSelect>
                    </MenuBar>
                </StyledAccountContainer>
            </StyledHeaderContainer>
        </React.Fragment>
    );
};

export default Header;
