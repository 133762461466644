import styled from 'styled-components';

const StyledMainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
`;

export { StyledMainContainer };