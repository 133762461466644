import { gql } from 'graphql.macro';

export const GetLoggedInUserPermissions = gql`
    query {
        clientPermissions {
            permissionKey
            permissionName
            delete : deletePermission
            navigate: navigatePermission
            read: readPermission
            write: writePermission
        }
        logedinEmployeeUserId
    }
`;
