import React from 'react';
import { LoadingProvider } from 'utils/loading/index';
import { LoadingState } from 'utils/loading/types/LoadingState';
import { LoadingTuple } from 'utils/loading/types/LoadingTuple';

export const withLoadingContext = <TLoading extends string | number, TProps = {}>(
    WrappedComponent: React.FC<TProps>,
    initialLoadingState: LoadingState<TLoading>,
    loadingContext?: React.Context<LoadingTuple<TLoading>>
) => {
    const LoadingWrappedComponent: React.FC<TProps> = (props: TProps): React.ReactElement => {
        return (
            <LoadingProvider loadingState={initialLoadingState} loadingContext={loadingContext}>
                <WrappedComponent {...props} />
            </LoadingProvider>
        );
    };

    LoadingWrappedComponent.displayName = `withLoading(${WrappedComponent.displayName})`;

    return LoadingWrappedComponent;
};
