import { SortOrder } from 'sections/customer-accounts/shared/enums/SortOrder.enum';

const toDate = new Date();
const fromDate = new Date();
fromDate.setMonth(toDate.getMonth() - 6);

export const initialState = {
    creditApplicationListState: {
        remoteFilters: {
            user: null,
            statuses: [],
            dateFrom: fromDate.toISOString(),
            dateTo: toDate.toISOString(),
            onlyExpired: false,
            __typename: 'CreditApplicationListRemoteFilters'
        },
        localFilters: {
            users: [],
            statuses: [],
            filter: '',
            __typename: 'CreditApplicationListLocalFilters'
        },
        localSort: {
            order: SortOrder.Asc,
            value: 'applicationDate',
            __typename: 'CreditApplicationListLocalSort'
        },
        __typename: 'CreditApplicationListState'
    }
};
