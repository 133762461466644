import { isNullOrUndefined } from './validation.utils';
import { SortOrder } from 'sections/customer-accounts/shared/enums/SortOrder.enum';

export function addOrRemove<ItemType>(
    array: ItemType[],
    item: ItemType,
    uniqueValueProvider: (item: ItemType) => unknown
): ItemType[] {
    let newArray: ItemType[];

    const currentItem = array.find(x => uniqueValueProvider(x) === uniqueValueProvider(item));

    if (!isNullOrUndefined(currentItem)) {
        newArray = [...array.filter(x => uniqueValueProvider(x) !== uniqueValueProvider(item))];
    } else {
        newArray = [...array, item];
    }

    return newArray;
}

export const sortByProperty = <TEntity>(
    array: TEntity[],
    propertyName: keyof TEntity,
    sortDirection: SortOrder = SortOrder.Asc
): TEntity[] => {
    return [...array].sort((a, b) => {
        const greaterThanSort = sortDirection === SortOrder.Asc ? 1 : -1;
        const lessThanSort = sortDirection === SortOrder.Asc ? -1 : 1;

        if (a[propertyName] > b[propertyName]) return greaterThanSort;

        if (a[propertyName] < b[propertyName]) return lessThanSort;

        return 0;
    });
};
