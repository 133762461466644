import React, { Suspense, useMemo } from 'react';
import { SecureRoute } from '@okta/okta-react';
import { Redirect, Switch } from 'react-router-dom';
import Header from 'components/shared/Header';
import Body from 'components/shared/Body';
import SideMenu from 'components/shared/SideMenu';
import Main from 'components/shared/Main';
import { StyledAppContainer } from '../styled/App.styled';
import { createGraphqlClient } from 'store';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastProvider } from 'react-toast-notifications';
import { RestClient } from 'utils/rest-client/RestClient';
import { EndPoint } from 'utils/rest-client/EndPoint';
import { RestProvider } from 'utils/rest-client';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import PermissionProvider from 'utils/permissions/components/PermissionProvider';
import { IEndPoints } from 'utils/rest-client/types/IEndPoints';
import DailyReminders from '../../../../utils/daily-reminders/components/DailyReminders';
import { useSecurity } from 'utils/security/hooks/useSecurity';
import { AuthStateEnum } from 'utils/security/constants/AuthStateEnum';
import {
    CustomerAccountRoute,
    customerAccountRoutes
} from 'sections/customer-accounts/shared/routes/customer-accounts.routes';
import { routes as appRoutes, AppSectionRoute } from 'routes/app.routes';
import { Authorising } from 'components/shared/App/components/Authorising';
import { ToastContainer } from 'components/shared/App/components/ToastContainer';
import CreditLimitReviewReportPage from 'sections/customer-accounts/report/pages/CreditLimitReview';

const WatchListView = React.lazy(() => import('components/shared/WatchListView'));
const AmendCustomer = React.lazy(() => import('sections/customer-accounts/customers/pages/AmendCustomer'));
const CustomerConfiguration = React.lazy(
    () =>
        import('sections/customer-accounts/configuration/pages/CustomerConfiguration/components/CustomerConfiguration')
);
const SearchHome = React.lazy(() => import('sections/search/pages/home'));
const CreditApplicationList = React.lazy(() => import('sections/customer-accounts/applications/pages/ApplicationList'));
const NewApplication = React.lazy(() => import('sections/customer-accounts/applications/pages/NewApplication'));
const Watchlist = React.lazy(() => import('sections/customer-accounts/watchlist/pages/Watchlist'));
const NewWatchlist = React.lazy(() => import('sections/customer-accounts/watchlist/pages/NewWatchList'));
const AmendApplication = React.lazy(() => import('sections/customer-accounts/applications/pages/AmendApplication'));
const PerformanceDashboardPage = React.lazy(
    () => import('sections/customer-accounts/dashboard/pages/PerformanceDashboardPage')
);

/**
 * AppContainer component
 */
const AppContainer = () => {
    const { settings } = useConfig<IAppConfig>();
    const { authState, accessToken, user } = useSecurity();

    const graphQlClient = useMemo(
        () => createGraphqlClient(settings.GraphQlApiUrl, accessToken),
        [settings, accessToken]
    );

    const restClient = useMemo(
        () =>
            new RestClient<IEndPoints>({
                endpoints: {
                    fileUpload: new EndPoint({
                        url: settings.FileUrl,
                        token: accessToken
                    }),
                    emptyUrl: new EndPoint({
                        url: '',
                        token: accessToken
                    })
                }
            }),
        [settings, accessToken]
    );

    return (
        <>
            {authState !== AuthStateEnum.Successful && <Authorising authState={authState} />}
            {authState === AuthStateEnum.Successful && (
                <ApolloProvider client={graphQlClient}>
                    <RestProvider<IEndPoints> client={restClient}>
                        <ToastProvider
                            autoDismiss={true}
                            autoDismissTimeout={10000}
                            placement='top-right'
                            components={{ ToastContainer }}
                        >
                            <PermissionProvider user={user}>
                                <DailyReminders>
                                    <StyledAppContainer>
                                        <Header />
                                        <Main>
                                            <SideMenu />
                                            <Body>
                                                <Suspense fallback={<div>&nbsp;</div>}>
                                                    <Switch>
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.PerformanceDashboard
                                                            ]()}
                                                            component={PerformanceDashboardPage}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.CreditLimitReport
                                                            ]()}
                                                            component={CreditLimitReviewReportPage}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.NewApplication
                                                            ]()}
                                                            component={NewApplication}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.ViewApplication
                                                            ]()}
                                                            component={AmendApplication}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.ViewAccount
                                                            ]()}
                                                            component={AmendCustomer}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.Applications
                                                            ]()}
                                                            component={CreditApplicationList}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.NewWatchlist
                                                            ]()}
                                                            component={NewWatchlist}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.ViewWatchListItem
                                                            ]()}
                                                            component={WatchListView}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.Watchlist
                                                            ]()}
                                                            component={Watchlist}
                                                        />
                                                        <SecureRoute
                                                            path={appRoutes[AppSectionRoute.Search]}
                                                            component={SearchHome}
                                                        />
                                                        <SecureRoute
                                                            path={customerAccountRoutes[
                                                                CustomerAccountRoute.Configuration
                                                            ]()}
                                                            component={CustomerConfiguration}
                                                        />
                                                        <Redirect
                                                            to={customerAccountRoutes[
                                                                CustomerAccountRoute.PerformanceDashboard
                                                            ]()}
                                                        />
                                                        <Redirect to={'/'} />
                                                    </Switch>
                                                </Suspense>
                                            </Body>
                                        </Main>
                                    </StyledAppContainer>
                                </DailyReminders>
                            </PermissionProvider>
                        </ToastProvider>
                    </RestProvider>
                </ApolloProvider>
            )}
        </>
    );
};

export default AppContainer;
