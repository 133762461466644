import { EnumDetailEntity } from "../types/EnumDetailEntity";

export function mapToEnumOptions<T extends object>(v: T): EnumDetailEntity[]{
    const keys = Object.keys(v).filter((v) => isNaN(Number(v)));
    const values = Object.values(v).filter((v) => !isNaN(Number(v)));

    return keys.map((v, i) => {
        return {
            key: parseInt(values[i]),
            value: v
        };
    });
}