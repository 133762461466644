import { gql } from 'graphql.macro';

export const GetNotDestroyedItems = gql`
    query GetNotDestroyedItems{
        downloadedApplicationsNotDestroyed{
            createdBy
            dateCreated
            downloadId
            fileName
            lastUpdatedDate
            referenceId
        }
    }
`;