/**
 * Creates a reducer for a state object using the supplied collection of handlers.
 * @param handlers The collection of handlers for actions {A} carried out on the supplied state {S}.
 */
export function createReducer<TState, TActions extends Enum>(handlers: ActionHandlerMapObject<TState, TActions>) {
    return function reducer(state: TState, action: Action<TActions, unknown>) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}

/**
 * Creates an ActionCreator for the supplied action type.
 * @param type The action type.
 * @returns An ActionCreator.
 */
export const actionCreatorFactory = <TActions extends Enum, TPayload = undefined>(
    type: TActions[keyof TActions]
): IActionCreator<TActions, TPayload> => (payload?: TPayload): Action<TActions, TPayload> => ({
    type: type,
    payload: payload
});
