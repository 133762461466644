import React from 'react';
import { StyledNoDataRoot } from '../styled/NoData.styled';
import { FontIcon } from '@fluentui/react';
import { isNullOrUndefined } from 'utils/validation.utils';
import Button from 'components/general/Button';

interface INoDataProps {
    /** The children will be displayed if hasData is true. */
    children: any;
    /** Whether there is any data to display, if not, the no data component will be displayed. */
    hasData: boolean;
    /** The name of the icon to be displayed, by default this is PageData.
     * Note: A list of available icons can be found at [https://developer.microsoft.com/en-us/fabric#/styles/web/icons].
     * Default: [PageData].
     */
    iconName?: string;
    /** The function to be called when the user clicks the action button.
     * Note: If this is left undefined, no action button will be displayed.
     */
    onAction?: () => void;
    /** The text to be displayed on the action button.
     * Default: 'Create'
     */
    onActionText?: string;
    /** Icon displayed on the action button.
     * Default: 'Add' */
    onActionIconName?: string;
    /** Helpful label that will be displayed to help the user understand what data is missing. */
    label?: string;
    /** Helpful label that will be displayed to help the user understand what data is missing. */
    description?: string;
}

const NoData = ({
    children,
    hasData,
    iconName = 'PageData',
    onAction,
    onActionText = 'Create',
    onActionIconName = 'Add',
    label,
    description
}: INoDataProps) => {
    return (
        <React.Fragment>
            {!hasData && (
                <StyledNoDataRoot>
                    <FontIcon iconName={iconName} />
                    {!isNullOrUndefined(label) && <h1>{label}</h1>}
                    {!isNullOrUndefined(description) && <h2>{description}</h2>}
                    {!isNullOrUndefined(onAction) && (
                        <Button onClick={onAction} text={onActionText} iconName={onActionIconName} />
                    )}
                </StyledNoDataRoot>
            )}
            {hasData && children}
        </React.Fragment>
    );
};

export default NoData;
