import React, { useMemo, useState } from 'react';
import { StyledMenuContainer } from '../styled/SideMenu.styled';
import SideMenuAction from './SideMenuAction';
import { ChevronsLeft, ChevronsRight } from 'styled-icons/feather';
import { Settings } from 'styled-icons/material';
import { ReactComponent as UserLogo } from 'assets/Images/userLogo.svg';
import { AppSectionRoute, routes as appRoutes } from 'routes/app.routes';
import { FontIcon } from '@fluentui/react';
import {
    CustomerAccountRoute,
    customerAccountRoutes
} from 'sections/customer-accounts/shared/routes/customer-accounts.routes';
import { SideMenuLink } from 'components/shared/SideMenu/index';
import { usePermissions } from 'utils/permissions/hooks/usePermissions';
import { ApplicationPermission } from 'sections/customer-accounts/applications/enums/ApplicationPermission';
import { PermissionType } from 'utils/permissions/types/PermissionType';
import { useConfig } from 'utils/config/hooks/useConfig';
import { IAppConfig } from 'config/types/IAppConfig';
import { ConditionalRender } from 'components/general/ConditionalRender';
import { EnvironmentEnum } from 'config/enums/Environment.enum';
import { theme } from 'themes/aplant.theme';
import { useSecurity } from 'utils/security/hooks/useSecurity';

const SideMenu = () => {
    const { accessToken } = useSecurity();
    const [isExpanded, setIsExpanded] = useState(false);
    const { hasPermission, initialised: permissionsInitialised } = usePermissions();
    const { settings } = useConfig<IAppConfig>();

    async function copyTokenToClipboard() {
        await navigator.clipboard.writeText(accessToken ?? '');
    }

    const menuItems = useMemo(() => {
        const items = [];

        items.push({
            title: 'Customer application performance dashboard',
            label: 'Performance Dashboard',
            icon: <FontIcon iconName='BIDashboard' />,
            to: customerAccountRoutes[CustomerAccountRoute.PerformanceDashboard]()
        });

        if (
            permissionsInitialised &&
            hasPermission({
                permissionKey: ApplicationPermission.CreditReviewReport,
                permissionTypes: [PermissionType.Read]
            })
        ) {
            items.push({
                title: 'Credit Limit Review Report',
                label: 'Credit Limit Review',
                icon: <FontIcon iconName='SkypeCircleCheck' />,
                to: customerAccountRoutes[CustomerAccountRoute.CreditLimitReport]()
            });
        }

        items.push({
            title: 'Customer applications',
            label: 'Applications',
            icon: <FontIcon iconName='AddFriend' />,
            to: customerAccountRoutes[CustomerAccountRoute.Applications]()
        });

        if (
            permissionsInitialised &&
            hasPermission({
                permissionKey: ApplicationPermission.Watchlist,
                permissionTypes: [PermissionType.Read]
            })
        )
            items.push({
                title: 'Company watchlist',
                label: 'Watchlist',
                icon: <FontIcon iconName='ShieldAlert' />,
                to: customerAccountRoutes[CustomerAccountRoute.Watchlist]()
            });

        return items;
    }, [permissionsInitialised, hasPermission]);

    const configMenuItems = useMemo(
        () => [
            {
                title: 'Customer Account Settings',
                label: 'Customer',
                icon: <FontIcon iconName='PlayerSettings' />,
                to: customerAccountRoutes[CustomerAccountRoute.Configuration]()
            }
        ],
        []
    );

    return (
        <StyledMenuContainer isExpanded={isExpanded}>
            <SideMenuLink
                icon={<UserLogo style={{ width: '33px' }} />}
                label='Customers'
                to={appRoutes[AppSectionRoute.CustomerAccounts]}
                title='Customer accounts'
                items={menuItems}
            />

            <SideMenuLink
                icon={<Settings size='24px' style={{ color: theme.palette.secondary.main }} />}
                label='Configuration'
                to={customerAccountRoutes[CustomerAccountRoute.Configuration]()}
                title='Account Management Configuration'
                style={{ marginTop: 'auto', borderTop: '1px solid #484848' }}
                items={configMenuItems}
            />

            <ConditionalRender
                condition={
                    settings.Environment === EnvironmentEnum.Development ||
                    settings.Environment === EnvironmentEnum.Test
                }>
                <SideMenuAction
                    icon={<FontIcon iconName='Ticket' style={{ color: 'white' }} />}
                    label='Access Token'
                    title='Copy the access token to clipboard'
                    onClick={copyTokenToClipboard}
                />
            </ConditionalRender>

            <SideMenuAction
                icon={
                    isExpanded ? (
                        <ChevronsLeft strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                    ) : (
                        <ChevronsRight strokeWidth='2px' size='24px' style={{ color: 'white' }} />
                    )
                }
                label={isExpanded ? 'Collapse' : 'Open'}
                onClick={() => setIsExpanded(!isExpanded)}
            />
        </StyledMenuContainer>
    );
};

SideMenu.displayName = 'SideMenu';

export default SideMenu;
