export function isUndefined(value: any): value is undefined {
    return value === undefined;
}

export function isNull(value: any): value is null {
    return value === null;
}

/** Returns whether a value is null or undefined */
export function isNullOrUndefined(value: any): value is null | undefined {
    return value === null || value === undefined;
}

export function isNullOrEmpty(value: any): value is null | undefined | '' {
    return value === null || value === undefined || value === '';
}

export function anyNullOrUndefined<T>(values: T[]): values is T[] {
    return values.filter(value => value === null || value === undefined).length > 0;
}

export function anyNullOrEmpty<T extends string>(values: (T | null | undefined)[]): boolean {
    return values.filter(value => value === null || value === undefined || value === '').length > 0;
}

export function allNullOrEmpty<T extends string>(values: (T | null | undefined)[]): boolean {
    return values.filter(value => value === null || value === undefined || value === '').length === values.length;
}

export function isEmpty<T extends object>(value: T): boolean {
    for (const key in value) {
        if (value.hasOwnProperty(key)) return false;
    }
    return true;
}
